import Zotero from './zotero';


export default (di) => {
	di
		.setType({type: Zotero, name: 'Zotero', parent: 'PageComponent', params: {
				baseUrl: di.lazyValue('baseUrl')
			}})
	;
};
