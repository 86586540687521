import Masonry from 'masonry-layout';
import PageComponent from '../../common/component/page-component';

class DetailedMenu extends PageComponent {

	constructor({root, element, gridContainerClass = '.wzb-js-gridContainer', gridElementClass = '.wzb-js-gridElement'}) {
		super({root: root, element: element});
		this.gridContainerClass = gridContainerClass;
		this.gridElementClass = gridElementClass;
	}

	prepare() {
		this.masonry = new Masonry(this.element.querySelector(this.gridContainerClass), {
			itemSelector: this.gridElementClass,
			horizontalOrder: true,
			transitionDuration: 0});

		this.masonry.layout();
		this.listeners.context = this.events.on(this.root, 'context:switchcomplete', this.onContextSwitch.bind(this));
		this.listeners.focus = this.events.on(window, 'focus', this.onFocus.bind(this));
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.listeners.load = this.events.on(window, 'load', this.onLoad.bind(this));

		setTimeout(this.layout.bind(this), 1000);
	}

	layout() {
		if(this.masonry) this.masonry.layout();
	}

	start() {
		this.layout();
	}

	onContextSwitch(event) {
		this.layout();
	}

	onResize(e) {
		this.layout();
	}

	onFocus(event) {
		this.layout();
	}

	onLoad(event) {
		this.layout();
	}

}

export default DetailedMenu;
