import {isNumber} from './types';


export function getViewportWidth() {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}


export function getViewportHeight() {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}


export function getDocumentHeight() {
    const body = document.body;
    const html = document.documentElement;
    return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
}


export function isInsideViewportHeight(top, bottom) {
    if (!isNumber(top)) {
        const rect = top.getBoundingClientRect();
        top = rect.top;
        bottom = rect.bottom;
    }
    return (top < getViewportHeight() && bottom > 0);
}


export function isPointInsideViewportHeight(triggerPoint) {
    if (!isNumber(triggerPoint)) {
        const rect = triggerPoint.getBoundingClientRect();
        triggerPoint = rect.top + (rect.height / 2);
    }
    return (triggerPoint > 0 && triggerPoint < getViewportHeight());
}
