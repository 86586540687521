import PageComponent from '../../common/component/page-component';

class MenuToggler extends PageComponent {

	constructor({root, element, contextName = 'menu', closeClass = 'close'}) {
		super({root: root, element: element});
		this.contextName = contextName;
		this.closeClass = closeClass;
	}

	prepare() {
		this.listeners.click = this.events.on(this.element, 'click', this.onToggle.bind(this));
	}


	onToggle(event, target) {
		event.preventDefault();
		this.classList(this.element).toggle(this.closeClass, this.contexts.getCurrentContext().getName() !== this.contextName);
		this.contexts.toggle(this.contextName);
	}

}


export default MenuToggler;
