import {Noise} from 'noisejs';
import {getViewportWidth} from '../../common/utils/size';
import getPointerPosition from '../../common/utils/get-pointer-position';
import PageComponent from '../../common/component/page-component';

class DashesAnimation extends PageComponent {

	constructor({
		root,
		element,
		canvasAttribute = 'dashesAnimationCanvas',
		color = 0x000000,
		gridSizeBig = 31, //px
		gridSizeSmall = 23,
		increment = 1/1000,
		rotationStrength = 3,
		thickness = 0.2, //px
		tileSizeBig = 10, //px
		tileSizeSmall = 7,
		maxXGridNumber = 41,
		yGridNumberBig = 10,
		yGridNumberSmall = 8,
		breakpoint = 1024, //main breakpoint
		mouseDelay = 60,
		maxDistance = 700,
		density = 0.028,
		swirlSize = 600,
		maxOrientationAngle = 30,
		swirlStrength = 0.0017
		}) {
		super({root: root, element: element});

		this.canvasAttribute = canvasAttribute;
		this.color = color;
		this.density = density;
		this.gridSizeBig = gridSizeBig;
		this.gridSizeSmall = gridSizeSmall;
		this.increment = increment;
		this.rotationStrength = rotationStrength;
		this.thickness = thickness;
		this.tileSizeBig = tileSizeBig;
		this.tileSizeSmall = tileSizeSmall;
		this.maxXGridNumber = maxXGridNumber;
		this.yGridNumberBig = yGridNumberBig;
		this.yGridNumberSmall = yGridNumberSmall;
		this.breakpoint = breakpoint;
		this.mouseDelay = mouseDelay;
		this.maxDistance = maxDistance;
		this.swirlSize = swirlSize;
		this.maxOrientationAngle = maxOrientationAngle;
		this.swirlStrength = swirlStrength;

		this.inside = false;

		this.position = 0;
		this.lastXPosition = 0;
		this.xPosition = 0;
		this.lastYPosition = 0;
		this.yPosition = 0;
		this.speed = 0;
		this.gridSize = gridSizeBig;
		this.tileSize = tileSizeBig;
		this.yGridNumber = yGridNumberBig;
		this.xGridNumber = maxXGridNumber;
		this.mouseDelayedX = 0;
		this.mouseDelayedY = 0;
	}

	prepare() {
		this.canvas = this.element.querySelector(this.dataSelector(this.canvasAttribute));
		this.canvasContex = this.canvas.getContext('2d');

		this.onResize();

		this.startIntersectionObserver();

		this.noise = new Noise();

		//const canvasRect = this.canvas.getBoundingClientRect();
		//this.mouseDelayedX = (canvasRect.right - canvasRect.left)/2;
		//this.mouseDelayedY = (canvasRect.bottom - canvasRect.top)/2;

		this.listeners.deviceorientation = this.events.on(window, 'deviceorientation', this.onOrientationChange.bind(this));

		this.listeners.mousemove = this.events.on(this.element, 'mousemove touchmove', this.onMouseMove.bind(this));

		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));

		requestAnimationFrame(this.onEF.bind(this));
	}

	setSize(w, h) {
		this.canvas.setAttribute('width', w);
		this.canvas.setAttribute('height', h);
	}

	startIntersectionObserver() {
		this.observer = new IntersectionObserver(this.onInside.bind(this), {
			threshold: 0
		});
		this.observer.observe(this.element);
	}

	onEF() {
		this.position += this.increment;
		if(this.inside) {
			this.drawFrame(this.position);
		}
		requestAnimationFrame(this.onEF.bind(this));
	}


	drawFrame() {
		this.position += this.increment;

		this.mouseDelayedX += (this.xPosition - this.mouseDelayedX) / this.mouseDelay;
		this.mouseDelayedY += (this.yPosition - this.mouseDelayedY) / this.mouseDelay;

		this.canvasContex.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
		this.canvasContex.save();
		this.canvasContex.beginPath();
		let posX;
		let posY;
		let noise1d;
		let l;
		let cosL;
		let sinL;
		let x;
		let y;

		const noiseAlt = this.noise.simplex3(this.position, this.position, this.position);

		for (x = 0; x < this.xGridNumber; x++) {
			for (y = 0; y < this.yGridNumber; y++) {
				posX = (x + 0.5) * this.gridSize;
				posY = (y + 0.5) * this.gridSize;

				const xDist = posX - this.mouseDelayedX;
				const yDist = posY - this.mouseDelayedY;

				const angle = -Math.atan2(xDist, yDist) - 1.5708;

				const dist = Math.sqrt((xDist*xDist)+(yDist*yDist));

				noise1d = this.noise.simplex3(this.position + x * this.density, 1 - this.position + y * this.density, this.position);
				l =  noise1d * this.rotationStrength;

				const distVal = Math.max(this.swirlSize - dist, 0)*noiseAlt*this.swirlStrength;

				//angle to mouse position + swirl + perlin noise
				const a = angle + distVal + l;
				//const a = l;
				//const a = angle;
				//const a = distVal;

				cosL = Math.cos(a);
				sinL = Math.sin(a);

				this.canvasContex.moveTo(posX - this.tileSize * cosL, posY - this.tileSize * sinL);
				this.canvasContex.lineTo(posX + this.tileSize * cosL, posY + this.tileSize * sinL);
			}
		}
		this.canvasContex.stroke();
		this.canvasContex.fill();
		this.canvasContex.restore();
	}


	onInside(entries, observer) {
		for (const entry of entries) {
			const inside = entry.intersectionRatio > 0;
			if (inside !== this.inside) {
				this.inside = inside;
			}
			break;
		}
	}

	onMouseMove(event) {
	  //const rect = event.target.getBoundingClientRect();
		//const rect = this.element.getBoundingClientRect();
		const canvasRect = this.canvas.getBoundingClientRect();

		const pos = getPointerPosition(event);
	  this.xPosition = pos.x - canvasRect.left;
	  this.yPosition = pos.y - canvasRect.top;
	}

	onOrientationChange(event) {
		if(event.gamma === null || event.beta === null) return;
		console.log(event.gamma, event.beta);
		const rect = this.element.getBoundingClientRect();
		const canvasRect = this.canvas.getBoundingClientRect();
		const right = Math.min(rect.right, canvasRect.right);

		const gammaNorm = (Math.min(Math.max(-this.maxOrientationAngle, -event.gamma), this.maxOrientationAngle)+this.maxOrientationAngle)/(this.maxOrientationAngle*2);
		const betaNorm = (Math.min(Math.max(-this.maxOrientationAngle, 45-event.beta), this.maxOrientationAngle)+this.maxOrientationAngle)/(this.maxOrientationAngle*2);

		this.xPosition = gammaNorm * right;
		console.log(betaNorm);
		this.yPosition = betaNorm * canvasRect.bottom;// + canvasRect.top;
	}

	onResize(e) {
		const w = getViewportWidth();
		let size;
		if(w > this.breakpoint) {
			size = 'big';
			this.gridSize = this.gridSizeBig;
			this.tileSize = this.tileSizeBig;
			this.yGridNumber = this.yGridNumberBig;
		} else {
			size = 'small';
			this.gridSize = this.gridSizeSmall;
			this.tileSize = this.tileSizeSmall;
			this.yGridNumber = this.yGridNumberSmall;
		}

		const newGridSize = Math.min(this.maxXGridNumber, Math.ceil(w/this.gridSize)-1);

		if(this.currentSize !== size || this.xGridNumber !== newGridSize) {
			this.xGridNumber = newGridSize;
			this.canvasWidth = this.gridSize * this.xGridNumber;
			this.canvasHeight = this.gridSize * this.yGridNumber;

			this.setSize(this.canvasWidth, this.canvasHeight);
		}

		this.currentSize = size;
	}
}

export default DashesAnimation;
