import Swiper from 'swiper/bundle';

import 'swiper/css/bundle';

import './scss/styles.scss';

if (process.env.NODE_ENV !== 'development') {
  //removes console logs in production
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
  console.info = () => {};
  console.debug = () => {};
}

import './js/main.js';

import './js/app.js';
