import Context from './context';
import ContextFactory from './context-factory';
import Contexts from './contexts';
import contextsMixin from './contexts-mixin';


export default (di) => {
	di
		.setType({type: Context, name: 'Context', mixins: ['domMixin', 'contextsMixin', 'componentsMixin']})

		.setType({type: ContextFactory, name: 'ContextFactory', parent: 'Factory'})
		.set('context/factory', di.lazyNew('ContextFactory'))


		.setType({type: Contexts, name: 'Contexts', mixins: ['domMixin'], setters: {
				injectContextFactory: di.lazyGet('context/factory')
			}
		})
		.set('context/contexts', di.lazyNew('Contexts'))

		.setMixin({mixin: contextsMixin, name: 'contextsMixin', setters: {
				injectContexts: di.lazyGet('context/contexts')
			}
		})
	;
};
