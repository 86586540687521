import {Component} from 'hyperhtml';
import {qr} from '../store/QueryState';

class SortOption extends Component {
	constructor(props) {
		super();
		this.props = props;
		qr.subscribe((pr) => {
			this.render();
		});
	}
	handleEvent(e) {
		const query = qr.props.query;
		query.sort = this.props.sort;
		query.page = 1;
		qr.props.setQuery(query);
	}
	render() {
		return this.html`
			<li class="m-dropdownborderless__item">
				<button class=${['m-dropdownborderless__itembutton', qr.props.query.sort === this.props.sort?'js-active':''].join(' ')} onClick="${this}">${this.props.label}</button>
			</li>
		`;
	}

}

export default SortOption;
