export default function escape(value, options = {}) {
	options = Object.assign({escapeSingleQuote: false, escapeTick: false, keepEntities: false}, options);
	value = String(value);
	if (!options.keepEntities) {
        value = value.replace(/&/g, '&amp;'); // first!
	}
	value = value
        .replace(/>/g, '&gt;')
        .replace(/</g, '&lt;')
        .replace(/"/g, '&quot;')
    ;

    if (options.escapeSingleQuote) {
        value = value.replace(/'/g, '&#39;');
    }

    if (options.escapeTick) {
        value = value.replace(/`/g, '&#96;');
    }

    return value;
}
