import Container from './common/di/container';
import config from './wzb/config/config';


const container = new Container();
config(container);

const app = container.get('app');

app.init().then(() => {
  app.execute();

}); 
