import {decamelize} from '../utils/string';
import {isObject, isArray} from '../utils/types';
import escape from '../template/escape';


class DataAttrParser {

	constructor({prefix = ''} = {}) {
		this.prefix = prefix;
	}


	getPrefix(prefix) {
		return this.prefix;
	}


	encodeValue(val) {
	    if (isObject(val) || isArray(val)) return JSON.stringify(val);
	    if (val === true) return 'true';
	    if (val === false) return 'false';
	    if (val === null) return 'null';
	    return val + '';
	}


	// inspired by jquery data attributes parsing
	decodeValue(val) {
	    if (typeof val === 'string') {
	        if (val === 'true') return true;
	        if (val === 'false') return false;
	        if (val === 'null') return null;

	        try {
	            // Only convert to a number if it doesn't change the string
	            if (+val + '' === val) return +val;
	            if (/^(?:\{[\w\W]*\}|\[[\w\W]*\])$/.test(val)) return JSON.parse(val);
	            return val;
	        } catch (e) {
	            return val;
	        }
	    }
	    return undefined;
	}


	buildString(name, value, prefix = undefined) {
		let attributes;
		if (value !== undefined) {
			attributes = {};
			attributes[name] = value;
		} else {
			attributes = name;
		}
		const strings = [];
		for (const key in attributes) {
			if (attributes.hasOwnProperty(key)) {
				strings.push(this.getAttributeName(key, prefix) + '="' + escape(this.encodeValue(attributes[key])) + '"');
			}
		}
		return strings.join(' ');
	}



	getAttributeName(name, prefix = undefined) {
		if (prefix === undefined) {
			prefix = this.prefix;
		}
		return 'data-' + prefix + decamelize(name);
	}


	getSelector(name, value = undefined, prefix = undefined) {
		let selector = '[' + this.getAttributeName(name, prefix);
		if (value !== undefined) {
			selector += '="' + this.encodeValue(value) + '"';
		}
		return selector + ']';
	}


	removePrefix(name, prefix = undefined) {
		if (prefix === undefined) {
			prefix = this.prefix;
		}
		if (prefix.length && name.substr(0, prefix.length) === prefix) {
			name = name.substr(prefix.length);
		}
		return name;
	}

}


export default DataAttrParser;
