import PageComponent from '../../common/component/page-component';

class GalleryToggler extends PageComponent {
	constructor({
								root,
								element,
								galleryContentAttribute = 'modalContent',
								targetContext = 'gallery',
								contentAttribute = 'imageContent'
							}) {
		super({root: root, element: element});

		this.galleryContentAttribute = galleryContentAttribute;
		this.galleryContentNode = null;
		this.targetContext = targetContext;
		this.contentAttribute = contentAttribute;
	}

	prepare() {
		this.modalContent = document.querySelector(this.dataSelector(this.galleryContentAttribute));
		this.listeners.click = this.events.on(this.element, 'click', this.onClick.bind(this));
	}

	onClick() {
		const content = this.element.querySelector(this.dataSelector(this.contentAttribute));
		this.modalContent.innerHTML = content.innerHTML;
		this.contexts.toggle(this.targetContext);
	}

	findContentNode(el) {
		const contentNode = el.querySelector(this.dataSelector(this.galleryContentAttribute));
		if (contentNode) {
			return contentNode.firstElementChild;
		} else if (el.parentNode)  {
			 return this.findContentNode(el.parentNode);
		}
		return false;
	}
}

export default GalleryToggler;
