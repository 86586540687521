class ClassListParser {

	constructor({prefix = '', basePrefix = ''} = {}) {
		this.prefix = prefix;
		this.basePrefix = basePrefix;
	}


	getSelector(name, prefix = undefined) {
		if (prefix === undefined) {
			prefix = this.prefix;
		}
		return '.' + prefix + name;
	}


	getBaseSelector(name, prefix = undefined) {
		if (prefix === undefined) {
			prefix = this.basePrefix;
		}
		return '.' + prefix + name;
	}


	buildString(names, prefix = undefined) {
		if (!Array.isArray(names)) {
			names = [names];
		}
		if (prefix === undefined) {
			prefix = this.prefix;
		}

		return names.map((name) => (name.length ? prefix + name : '')).join(' ');
	}


	buildBaseString(names, prefix = undefined) {
		if (!Array.isArray(names)) {
			names = [names];
		}
		if (prefix === undefined) {
			prefix = this.basePrefix;
		}

		return names.map((name) => (name.length ? prefix + name : '')).join(' ');
	}


	addPrefix(classes, prefix = undefined) {
		if (prefix === undefined) {
			prefix = this.prefix;
		}
		for (let i = 0, end = classes.length; i < end; i++) {
			classes[i] = prefix + classes[i];
		}
		return classes;
	}


	removePrefix(className, prefix = undefined) {
		if (prefix === undefined) {
			prefix = this.prefix;
		}
		if (className.substr(0, prefix.length) === prefix) {
			className = className.substr(prefix.length);
		}
		return className;
	}

}


export default ClassListParser;
