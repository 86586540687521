import PageComponent from '../../common/component/page-component';

class Filter extends PageComponent {
	constructor({
					root,
					element,
					filterCategoryAttribute = 'filterCategory',
				}) {
		super({root: root, element: element});
		this.hasCheckedElement = false;
		this.filterCategoryAttribute = filterCategoryAttribute;
	}

	prepare() {
		this.filterName = this.dataAttr().get(this.filterCategoryAttribute);
	}

	emitChange() {
		this.events.trigger(this.element, 'filter:change');
	}

	getName() {
		return this.filterName;
	}

	getHasCheckedElement() {
		return this.hasCheckedElement;
	}

	getData() {
		// to be overriden
		return [];
	}
}

export default Filter;
