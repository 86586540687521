import {Component} from 'hyperhtml';
import {qr} from '../store/QueryState';

class PaginationButton extends Component {
	constructor(props) {
		super();
		this.props = props;
	}

	handleEvent(e) {
		const query = qr.props.query;
		query.page = this.props.page;
		qr.props.setQuery(query);

		const pos = document.getElementById('wzb-zotero').getBoundingClientRect().top;
		const offset = 130;
		if (pos < 0) {
			window.scrollTo(0, pos + window.scrollY - offset);
		}
	}

	render() {
		if (this.props.type === 'prev') {
			return this.html`
				<button onclick="${this}" class="m-zoteropagination__previous" data-page='${this.props.page}'></button>
			`;
		} else if (this.props.type === 'next') {
			return this.html`
				<button onclick="${this}" class="m-zoteropagination__next" data-page='${this.props.page}'></button>
			`;
		} else if (this.props.type === 'ellipsis') {
			return this.html`
				<button onclick="${this}" class="m-zoteropagination__more" data-page='${this.props.page}'>...</button>
			`;
		} else {
			return this.html`
				<button onclick="${this}" class=${['m-zoteropagination__page ', this.props.active ? 'm-zoteropagination__current wzb-js-active' : ''].join(' ')} data-page='${this.props.page}'>
					${this.props.page}
				</button>
			`;
		}
	}

}

export default PaginationButton;
