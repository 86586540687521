import Slide from './slide';


class VideoSlide extends Slide {

    constructor({
		root,
		element,
		autoload = false,
		loadedClass = 'loaded',
		defaultDuration = 1,
		slideVideoAttribute = 'slideVideo'
	}) {
		super({root: root, element: element, type: 'video', autoload: autoload, loadedClass: loadedClass, defaultDuration: defaultDuration});
		this.video = null;
		this.slideVideoAttribute = slideVideoAttribute;
    }


	getVideo() {
		if (!this.video) {
			this.video = this.slideVideoAttribute ?
				this.getComponent(this.element.querySelector(this.dataSelector(this.slideVideoAttribute))) :
				this.getComponent('VideoPlayer')
			;
		}
		return this.video;
	}


    load() {
		const video = this.getVideo();
		if (!video) {
			return super.load();
		}
		if (!this.loading && !this.loaded) {
			this.loading = true;
			video.load().then(() => {
				this.loaded = true;
				this.classList().add(this.dataAttr().get('loadedClass'));
				this.closeAsyncEvent('load');
				if (this.element) {
					this.events.trigger(this.element, 'slide:load', {component: this});
				}
				this.loading = false;
			});
		}
        return this.on('load');
	}


	stop() {
		super.stop();
		this.pause();
		return this;
	}


	pause() {
		super.pause();
		this.getVideo().pause();
		return this;
	}


}

export default VideoSlide;
