import ZoteroQuery from './zotero-query';


export default (di) => {
	di
		.setType({type: ZoteroQuery, name: 'ZoteroQuery', parent: 'PageComponent', params: {
			baseUrl: di.lazyValue('baseUrl')
		}})
	;
};
