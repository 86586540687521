import DefaultContext from './default-context';
import MenuContext from './menu-context';
import GalleryContext from './gallery-context';


export default (di) => {
	di
		.setType({type: DefaultContext, name: 'DefaultContext', parent: 'Context'})
		.setType({type: MenuContext, name: 'MenuContext', parent: 'Context'})
		.setType({type: GalleryContext, name: 'GalleryContext', parent: 'Context'})
	;
};
