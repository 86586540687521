import { compose, withState } from 'proppy';

const Z = compose(
	withState('items', 'setItems', []),
	withState('total', 'setTotal', ''),
	withState('itemTypes', 'setItemTypes', []),
	withState('tags', 'setTags', []),
	withState('excludeTags', 'setExcludeTags', []),
	withState('creators', 'setCreators', [])
);

const zt = Z();

export {
	zt
};
