import {TweenMax} from 'gsap';
import PageComponent from '../../common/component/page-component';

class Slider extends PageComponent {

	constructor({
		root,
		element,
		navigationAttribute = 'sliderButton',
		labelCurrentAttribute = 'sliderLabelCurrent',
		labelAllAttribute = 'sliderLabelAll',
		containerAttribute = 'movingContainer',
		contentAttribute = 'movingContent',
		slideAttribute = 'slide',
		clickRange = 5, // px
		duration = 0.6,
		easing = 'Power4.easeOut'
		}) {
		super({root: root, element: element});
		this.navigationAttribute = navigationAttribute;
		this.labelCurrentAttribute = labelCurrentAttribute;
		this.labelAllAttribute = labelAllAttribute;
		this.containerAttribute = containerAttribute;
		this.contentAttribute = contentAttribute;
		this.slideAttribute = slideAttribute;

		this.currentSlide = 0;
		this.slidesNo = 0;
		this.slideWidth = 0;
		this.currentContentPosition = 0;
		this.clickRange = clickRange;
		this.boundX = [0, 0];
		this.mouseDownCoordinates = {x: 0, y: 0};
		this.duration = duration;
		this.easing = easing;
	}

	prepare() {
		this.listeners.click = this.events.on(this.element, this.dataSelector(this.navigationAttribute), 'click', this.onButtonClick.bind(this));

		this.container = this.element.querySelector(this.dataSelector(this.containerAttribute));
		this.content = this.element.querySelector(this.dataSelector(this.contentAttribute));
		this.labelCurrent = this.element.querySelector(this.dataSelector(this.labelCurrentAttribute));
		this.labelAll = this.element.querySelector(this.dataSelector(this.labelAllAttribute));
		this.slides = this.element.querySelectorAll(this.dataSelector(this.slideAttribute));
		this.slidesNo = this.slides.length;

		this.listeners.mousedown = this.events.on(this.container, 'mousedown touchstart', this.onMouseDown.bind(this));
		this.listeners.dragstart = this.events.on(this.container, 'dragstart', this.onDragStart.bind(this));
		this.listeners.click = this.events.on(this.container, 'click', this.onClick.bind(this));

		this.gestures = new this.events.Hammer(this.container, {
			domEvents: true
		});
		this.gestures.get('pan').set({enable: true});
		this.events.on(this.container, 'panstart', this.onPanStart.bind(this));
		this.events.on(this.container, 'panmove', this.onPanMove.bind(this));
		this.events.on(this.container, 'panend', this.onPanEnd.bind(this));

		this.checkSlideSize();
		this.updateLabel();
	}

	checkSlideSize() {
		if (this.slides.length > 0) {
			this.slideWidth = this.slides[0].offsetWidth;
		}
	}

	//
	// Navigation
	//
	onButtonClick(event, target) {
		event.preventDefault();
		event.stopPropagation();
		const dir = this.dataAttr(target).get(this.navigationAttribute);
		this.goTo(dir);
	}

	goTo(dir) {
		if(dir === 'next') {
			this.goToNext();
		} else {
			this.goToPrev();
		}
	}

	goToNext() {
		this.currentSlide = ++this.currentSlide%this.slidesNo;
		this.goToSlide(this.currentSlide);
		this.updateLabel();
	}

	goToPrev() {
		this.currentSlide = this.currentSlide-1<0?this.slidesNo-1:this.currentSlide-1;
		this.goToSlide(this.currentSlide);
		this.updateLabel();
	}

	updateLabel() {
		this.labelCurrent.innerHTML = Slider.addZeroes(this.currentSlide + 1);
		this.labelAll.innerHTML = Slider.addZeroes(this.slidesNo);
	}

	updatePosition(x) {
		this.currentContentPosition = x;
		TweenMax.to(this.content, 0, {x: this.currentContentPosition});
	}

	goToSlide(slideId) {
		this.checkSlideSize();
		const toValue = -slideId*this.slideWidth;
		TweenMax.to(this.content, this.duration, {x: toValue, ease: this.easing});
		this.currentContentPosition = toValue;
	}

	checkClosest(x) {
		const closest = Math.min(this.slidesNo-1, Math.max(0, Math.round(-x/this.slideWidth)));
		if(closest !== this.closest) {
			this.currentSlide = closest;
			this.updateLabel();
		}
	}

	//prevent click if element was moved
	onMouseDown(event) {
		this.mouseDownCoordinates = {x: event.clientX, y: event.clientY};
	}

	onClick(event, target) {
		if ((event.clientX !== 0 || event.clientY !== 0) &&
			(this.mouseDownCoordinates.x !== 0 || this.mouseDownCoordinates.y !== 0) &&
			(Math.abs(event.clientX - this.mouseDownCoordinates.x) > this.clickRange ||
			Math.abs(event.clientY - this.mouseDownCoordinates.y) > this.clickRange)
		) {
			event.preventDefault();
		}
		this.mouseDownCoordinates = {x: 0, y: 0};
	}

	onDragStart(event) {
		event.preventDefault();
	}

	//paning
	onPanStart(event) {
		this.mouseDownContentPosition = this.currentContentPosition;
	}

	onPanMove(event) {
		const newValue = this.mouseDownContentPosition + event.gesture.deltaX;
		this.updatePosition(newValue);
		this.checkClosest(newValue);
	}

	onPanEnd(event) {
		this.goToSlide(this.currentSlide);
	}

	static addZeroes(i) {
		if(parseInt(i, 10) < 10) {
			return '0' + i;
		} else {
			return i;
		}
	}
}

export default Slider;
