import domMixin from '../dom/dom-mixin';
import listenerMixin from '../events/listener-mixin';
import asyncEventMixin from '../events/async-event-mixin';
import componentsMixin from './components-mixin';
import contextsMixin from '../context/contexts-mixin';
import componentConstants from './_constants';


class PageComponent extends contextsMixin(componentsMixin(asyncEventMixin(listenerMixin(domMixin())))) {

	constructor({root, element}) {
		super();
		this.root = root;
		this.element = element;
		this.context = null;
		this.defaults = {};
		this.cssDataDefaults = {};
		this.ready = false;
		this.active = false;
		this.firstStart = true;
		this.surviveDetached = false;
	}


	init(activate = true) {
		if (!this.ready) {
			this.selfDataAttr = this.dataAttr(this.element, this.defaults);
			this.selfCssData = this.cssData(this.element, this.cssDataDefaults);
			this.selfClassList = this.classList(this.element);

			this.resolveContext();
			this.prepare();
			if (activate) {
				this.activate();
			}
			this.ready = true;
		}
	}


	destroy() {
		if (this.ready) {
			if (this.active) {
				this.deactivate();
			}
			this.clear();
			this.destroyListeners();
			this.destroyAsyncEvents();
			this.root = null;
			this.element = null;
			this.context = null;
			this.defaults = null;
			this.ready = false;
		}
	}


	activate() {
		if (!this.active && this.canStart()) {
			this.start(this.firstStart);
			this.active = true;
			this.firstStart = false;
		}
		return this;
	}


	deactivate() {
		if (this.active && this.canStop()) {
			// guard against missing function
			if (typeof this.stop === 'function') {
				this.stop();
			}
			this.active = false;
		}
		return this;
	}


	prepare() {
	}


	clear() {}


	start(first) {}


	stop() {}


	resolveContext() {
		const contextValue = this.dataAttr().get(componentConstants.componentContextAttribute, true);
		this.context = null;
		if (contextValue === true) {
			const contextNode = this.element.parentNode ? this.element.parentNode.closest(this.dataSelector(componentConstants.contextAttribute)) : null;
			if (contextNode) {
				this.context = this.contexts.getContext(this.dataAttr(contextNode).get(componentConstants.contextAttribute));
			}
		} else if (contextValue) {
			this.context = this.contexts.getContext(contextValue);
		}
		return this.context;
	}


	canStart() {
		return !this.context || this.context.isActive();
	}


	canStop() {
		return true;
	}


	setSurviveDetached(value, nested = true) {
		value = !!value;
		this.surviveDetached = !!value;
		if (nested) {
			const subComponents = this.components.getComponents(this.element, '*');
			for (const subComponent of subComponents) {
				subComponent.setSurviveDetached(value, false);
			}
		}
		return this;
	}


	canSurviveDetached() {
		return this.surviveDetached;
	}


	getElement() {
		return this.element;
	}


	getContext() {
		return this.context;
	}


	isFirstStart() {
		return this.firstStart;
	}

}

export default PageComponent;
