import PageComponent from '../component/page-component';
import {getScrollTop} from '../utils/get-scroll';


class CollapsingHeader extends PageComponent {

	constructor({
		root,
		element,
		collapsedClass = 'collapsed'
	}) {
		super({root: root, element: element});
		this.defaults.collapsedClass = collapsedClass;

		this.size = 0;
		this.previousScrollPos = -1;
		this.collapsed = false;
	}


	prepare() {
		this.collapsedClass = this.dataAttr().get('collapsedClass');
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.listeners.scroll = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
		this.updateSize();
	}


	onResize(event) {
		this.updateSize();
	}


	onScroll(event) {
		this.updateState();
	}


	updateSize() {
		this.size = this.element.getBoundingClientRect().height;
		this.updateState();
	}


	updateState() {
		const scrollPos = getScrollTop();
		if (scrollPos !== this.previousScrollPos) {
			const direction = scrollPos >= this.previousScrollPos ?  1 : -1;
			const newCollapsed = (scrollPos > this.size && direction === 1);
			if (newCollapsed !== this.collapsed) {
				this.classList().toggle(this.collapsedClass, newCollapsed);
				this.collapsed = newCollapsed;
			}
			this.previousScrollPos = scrollPos;
		}
	}

}


export default CollapsingHeader;
