import Swiper from 'swiper';
import { Pagination } from 'swiper/modules';

export default function initSliders() {
  console.log('initSliders');
  initFrontpageHeroSlider();
}

function initFrontpageHeroSlider() {
  const hero = document.querySelector('.m-frontpage-hero');
  if (hero) {
    const slider = hero.querySelector('.swiper');
    const pagination = hero.querySelector(
      '.m-frontpage-hero__slider--pagination'
    );
    if (slider && pagination) {
      const swiper = new Swiper(slider, {
        loop: true,
        autoplay: {
          delay: 6000
        },
        autoHeight: true,
        pagination: {
          el: pagination,
          clickable: true,
        },
      });
    } else {
      hero.remove();
    }
  }
}
