import {Component} from 'hyperhtml';
import {qr} from '../store/QueryState';
import config from '../config';

class TagFilterItem extends Component {
	constructor(props) {
		super();
		this.props = props;
	}
	handleEvent(e) {
		const query = qr.props.query;
		query.tags =  query.tags.filter(tag => tag !== this.props.tag);
		if (e.target.checked) {
			query.tags.push(this.props.tag);
		}
		query.page = 1;
		qr.props.setQuery(query);
	}
	
	tagLabel(tag) {
		const tagLabel = config.tagLabels.find(t => t.tag === tag);
		if (tagLabel) {
			return tagLabel.label;
		} else {
			return tag;
		}
	}
	label(){ 
		var language = window.location.href;
		var languageEnglish = language.includes('/en/')?true:false;
		var placeholder = languageEnglish?this.props.en:this.props.de;
		return placeholder;
	}
	render() {
		return this.html`
			<li class="m-dropdown__item">
				<label class="m-dropdown__checkbox">
					<input class="m-dropdown__checkboxinput" onChange="${this}" ?checked=${this.props.active} type="checkbox" name="checkbox" value="${this.props.id}">
					<span class="m-dropdown__checkboxlabel">${this.tagLabel(this.props.tag)}</span>
				</label>
			</li>
		`;
	}

}

export default TagFilterItem;
