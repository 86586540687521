import {Component} from 'hyperhtml';
import {qr} from '../store/QueryState';
import {ux} from '../store/UxState';

class SearchCreator extends Component {
	constructor(props) {
		super();
		this.props = props;
		this.timer = null;
		qr.subscribe((pr) => {
			this.render();
		});
		ux.subscribe((pr) => {
			this.render();
		});
	}
	handleEvent(e) {
		const value = e.target.value;
		if (e.keyCode === 13) {
			e.stopPropagation();
			e.preventDefault();
			this.applySearch(value);
		} else {
			this.debounce(value);
		}
	}
	debounce(value) {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => this.applySearch(value), 500);
	}
	applySearch(value) {
		const query = qr.props.query;
		query.searchCreator = value;
		query.page = 1;
		qr.props.setQuery(query);
	}
	isHidden() {
		return ux.props.hide && ux.props.hide.includes('searchCreator');
	}
	placeholder(){
		var language = window.location.href;
		var languageEnglish = language.includes('/en/')?true:false;
		var placeholder = languageEnglish?"Search by person":"Nach Person suchen";
		return placeholder;
	}

	render() {
	return this.isHidden()?this.html`<div></div>`:this.html`
		<form class="m-zoterosearchbar">
			<div class="m-zoterosearchbar__wrapper">
				<input class="m-zoterosearchbar__input" onKeyDown="${this}" onKeyUp="${this}" id="searchCreator" name="searchCreator" type="text" placeholder=${this.placeholder()} value="${qr.props.query.searchCreator}">
				<button type="submit" class="m-zoterosearchbar__submit">
						<span class="m-zoterosearchbar__icon">
								<img class="l-home__logo" src="/typo3conf/ext/nn_wzb_base/Build/src/images/icons/ic-search.svg" width="24" height="24" alt="">
						</span>
				</button>
			</div>
		</form>
	`;
	}

}

export default SearchCreator;
