export default {
	mainItemTypes: [
		'book',
		'bookSection',
		'conferencePaper',
		'magazineArticle',
		'journalArticle',
		'presentation',
		'interview',
		'report'
	],
	tagLabels: [

		{tag: 'FG1', label: 'Arbeit'},
		{tag: 'FG2', label: 'Maker-Kultur'},
		{tag: 'FG7', label: '(Weiter-)Bildung'},
		{tag: 'FG9', label: 'Wohlbefinden'},
		{tag: 'FG11', label: 'Wissenschaft'},
		{tag: 'FG21', label: 'Wissenspraktiken'},
		{tag: 'FG3', label: 'Sharing Economy'},
		{tag: 'FG4', label: 'Datenmärkte'},
		{tag: 'FG5', label: 'GM-Innovationen'},
		{tag: 'FG8', label: 'Ungleichheit'},
		{tag: 'FG10', label: 'Integration'},
		{tag: 'FG12', label: 'Demokratie'},
		{tag: 'FG13', label: 'Citizenship'},
		{tag: 'FG14', label: 'Nachrichten'},
		{tag: 'FG15', label: 'Öffentlichkeit'},
		{tag: 'FG6', label: 'Verantwortung'},
		{tag: 'FG16', label: 'Normsetzung'},
		{tag: 'FG17', label: 'Vertrauen'},
		{tag: 'FG18', label: 'Regulierung'},
		{tag: 'FG19', label: 'Sicherheit'},
		{tag: 'FG20', label: 'KI-Systeme'},

    {tag: 'WDW', label: 'Wohlbefinden in der digitalen Welt'},
    {tag: 'DNT', label: 'Digitalisierung, Nachhaltigkeit, Teilhabe'},
    {tag: 'DDC', label: 'Design, Diversität und neue Commons (UdK)'},
    {tag: 'DSE', label: 'Daten, algorithmische Systeme und Ethik'},
    {tag: 'DDN', label: 'Dynamiken digitaler Nachrichtenvermittlung'},
    {tag: 'DII', label: 'Digitale Ökonomie, Internet, Ökosystem und Internet-Policy'},
    {tag: 'PAP', label: 'Plattform-Algorithmen und digitale Propaganda'},
    {tag: 'DDM', label: 'Dynamiken der digitalen Mobilisierung'},
    {tag: 'AKI', label: 'Arbeiten mit Künstlicher Intelligenz'},
    {tag: 'RVW', label: 'Reorganisation von Wissenspraktiken'},
    {tag: 'DOS', label: 'Digitalisierung und Öffnung der Wissenschaft (Fraunhofer/Fokus)'},
    {tag: 'BDW', label: 'Bildung in der digitalen Welt'},
    {tag: 'NEV', label: 'Normsetzung und Entscheidungsverfahren'},
    {tag: 'DVS', label: 'Digitalisierung und vernetzte Sicherheit'},
    {tag: 'STP', label: 'Sicherheit und Transparenz digitaler Prozesse (HU)'},
    {tag: 'TMH', label: 'Technik, Macht und Herrschaft (WZB)'},
    {tag: 'WPA', label: 'Weizenbaum Panel'},
    {tag: 'MET', label: 'Methodenlab'},
    {tag: 'MFO', label: 'Metaforschung'},
    {tag: 'FSY', label: 'Forschungssynthesen'},
  ],
  researchGroupMap: [
		{
			area: '',
			items: [
				'FG-übergreifend',
				'weizenbaum',
			]
		},
		{
			area: 'Digitale Technologien in der Gesellschaft',
			items: [
        'WDW',
        'DNT',
        'DDC',
        'DSE'
			]
		},
		{
			area: 'Digitale Märkte und Öffentlichkeiten auf Plattformen',
			items: [
        'DDN',
        'DII',
        'PAP',
        'DDM'
			]
		},
		{
			area: 'Organisation von Wissen',
			items: [
        'AKI',
        'RVW',
        'DOS',
        'BDW'
			]
		},
		{
			area: 'Digitale Infrastrukturen in der Demokratie',
			items: [
        'NEV',
        'DVS',
        'STP',
        'TMH'
			]
		},
    {
      area: 'Weizenbaum Digital Science Center',
      items: [
        'WPA',
        'MET',
        'MFO',
        'FSY'
      ]
    },
	],

	sortOptionsMap: [
		{
			label: 'Datum',
			sort: 'date'
		},
		{
			label: 'Titel',
			sort: 'title'
		},
		{
			label: 'Publikationstyp',
			sort: 'itemType'
		},
		{
			label: 'Publikationsorgan',
			sort: 'publicationType'
		}
	],

	creatorTypes: {
		author: '',
		performer: '',
		editor: 'Hrsg.: ',
		seriesEditor: 'Hrsg: ',
		contributor: 'Beitragend: ',
		podcaster: 'Podcaster: ',
		guest: 'Gast: ',
		artist: 'KünstlerIn: ',
		presenter: 'Vortragende/r: ',
		interviewer: 'InterviewerIn: ',
		interviewee: 'Gast: ',
		director: 'Regie: ',
	},

	optionals1: [
		{key: 'journalTitle', label: ''},
		{key: 'seriesTitle', label: ''},
		{key: 'publicationTitle', label: ''},
		{key: 'proceedingsTitle', label: ''},
		{key: 'libraryCatalog', label: ''},
		{key: 'conferenceName', label: ''},
		{key: 'bookTitle', label: ''},
		{key: 'blogTitle', label: ''},
		{key: 'interviewMedium', label: ''},
		{key: 'meetingName', label: ''},
	],

	optionals2: [
		{key: 'reportType', label: ''},
		{key: 'volume', label: 'Volume: '},
		{key: 'issue', label: 'Ausgabe: '},
		{key: 'pages', label: 'Seiten: '},
		{key: 'reportNumber', label: 'Nummer: '},
		{key: 'place', label: ''},
		{key: 'issn', label: 'ISSN: '},
		{key: 'isbn', label: 'ISBN: '}
	],

	initialQuery: {
		page: 1,
		itemTypes: [],
		reportTypes: [],
		tags: [],
    excludeTags: [],
		start: null, // ?string
		end: null, // ?string
		reviewed: null, // boolean
		search: null, // ?string
		searchCreator: null, // ?string
		sort: 'date', // string
	}
};
