export default function initEventFilters() {
	const filteredLists = document.querySelectorAll('.m-event-list-filtered').forEach(list => {
		const filterCheckboxes = list.querySelectorAll('.m-dropdown__checkboxinput')
		const listedEvents = list.querySelectorAll('.m-event-item')
		initShowMore()
		initialShowFive(listedEvents)

		filterCheckboxes.forEach(checkbox => {
			checkbox.addEventListener('change', () => {
				hideAllEvents(listedEvents)
				showEvents(listedEvents)
			})
		})
	})
}

function initShowMore() {
	const btn = document.querySelector('.m-event-list__show-more')
	btn?.addEventListener('click', () => {
		showFive()
	})
}


function getActiveFilters() {
	let filterCheckboxes = document.querySelectorAll('.m-event-list-filtered .m-dropdown__checkboxinput:checked')
	let result = []
	filterCheckboxes.forEach(checkbox => {
		result.push(checkbox.nextElementSibling.textContent)
	})
	return result
}

function hideAllEvents(events) {
	events.forEach(event => {
		event.classList.add('hidden')
		event.classList.remove('filtered-event')
	})
}

function showAllEvents(events) {
	events.forEach(event => {
		event.classList.remove('hidden')
	})
}

function showEvents(events) {
	let activeFilters = getActiveFilters()
	if (activeFilters.length > 0) {
		events.forEach(event => {
			activeFilters.forEach(filter => {
				if (event.dataset.type.includes(filter)) {
					// event.classList.remove('hidden')
					event.classList.add('filtered-event')
				}
			})
		})
	} else {
		showAllEvents(events)
	}
	showFive()
}

function initialShowFive(events) {
	hideAllEvents(events)
	for (let i = 0; i < 5; i++) {
		events[i]?.classList.remove('hidden')
	}
}

function showFive() {
	let filteredEvents = document.querySelectorAll('.m-event-list-filtered .m-event-item.filtered-event.hidden')
	let events = document.querySelectorAll('.m-event-list-filtered .m-event-item.hidden')
	if (getActiveFilters().length > 0) {
		for (let i = 0; i < 5; i++) {
			if (filteredEvents[i]) {
				filteredEvents[i].classList.remove('hidden')
			}
		}
		if (document.querySelectorAll('.m-event-list-filtered .m-event-item.filtered-event.hidden').length < 1) {
			document.querySelector('.m-event-list__show-more').classList.add('hidden')
		} else {
			document.querySelector('.m-event-list__show-more').classList.remove('hidden')
		}
	} else {
		for (let i = 0; i < 5; i++) {
			if (events[i]) {
				events[i].classList.remove('hidden')
			}
		}
	}
}