import Filter from './filter';

class FilterAlphabet extends Filter {
	constructor({
					root,
					element,
					activeClass = 'active',
					radioData = 'radio',
					allValue = 'all',
					articleAttribute = 'article',
					inactiveClass = 'inactive',
					letterData = 'filterLetter',
					radioInputData = 'radioInput',
					activeNoLetterClass = 'activeNoLetter',
				}) {
		super({root: root, element: element});
		this.activeClass = activeClass;
		this.radioData = radioData;
		this.allValue = allValue;
		this.articleAttribute = articleAttribute;
		this.inactiveClass = inactiveClass;
		this.letterData = letterData;
		this.radioInputData = radioInputData;
		this.activeNoLetterClass = activeNoLetterClass;
	}

	prepare() {
		super.prepare();

		this.letters = this.element.querySelectorAll(this.dataSelector(this.radioData));
		this.lettersNo = this.letters.length;

		this.listeners.radioChange = this.events.on(this.element, this.dataSelector(this.radioData), 'change', this.onCheckboxChange.bind(this));

		this.listeners.filterchange = this.events.on(document, 'filteredarticles:change', this.onFilteredArticlesChange.bind(this));
		this.onFilteredArticlesChange();
	}

	makeAllLettersInactive() {
		for (let i = 0; i < this.lettersNo; i++) {
			if (this.dataAttr(this.letters[i]).get(this.letterData) !== this.allValue) {
				this.classList(this.letters[i]).add(this.inactiveClass);
			}
		}
	}

	onFilteredArticlesChange(e) {
		this.makeAllLettersInactive();

		//TODO: is it a good way to search in a parent node?!!
		this.currentArticles = this.element.parentNode.querySelectorAll(this.dataSelector(this.articleAttribute) + this.classSelector(this.activeNoLetterClass));
		this.currentArticlesNo = this.currentArticles.length;

		for (let i = 0; i < this.currentArticlesNo; i++) {
			const article = this.currentArticles[i];
			const articleLetter = this.dataAttr(article).get(this.letterData);
			for (let j = 0; j < this.lettersNo; j++) {
				const letter = this.dataAttr(this.letters[j]).get(this.letterData);
				if (articleLetter === letter) {
					this.classList(this.letters[j]).remove(this.inactiveClass);
				}
			}
		}
	}

	onCheckboxChange(e) {
		this.checkHasCheckedElement();
		this.emitChange();
		this.getData();
	}

	getData() {
		const data = [];
		for (let i = 0; i < this.lettersNo; i++) {
			if (this.letters[i].querySelector(this.dataSelector(this.radioInputData)).checked === true) {
				const val = this.dataAttr(this.letters[i]).get(this.letterData);
				if (val !== this.allValue) {
					data.push(val);
				}
			}
		}

		return data;
	}

	checkHasCheckedElement() {
		let checkedElement = false;
		for (let i = 0; i < this.lettersNo; i++) {
			if (this.letters[i].querySelector(this.dataSelector(this.radioInputData)).checked === true && this.dataAttr(this.letters[i]).get(this.letterData) !== this.allValue) {
				checkedElement = true;
				break;
			}
		}
		this.hasCheckedElement = checkedElement;
	}
}

export default FilterAlphabet;
