import {Component} from 'hyperhtml';
import {ux} from '../store/UxState';
import SortOption from './sortOption';
import config from '../config';

class Sort extends Component {
	constructor(props) {
		super();
		this.props = props;
		this.sortOptionsMap = config.sortOptionsMap;

		ux.subscribe((pr) => {
			this.render();
		});
	}
	isHidden() {
		return ux.props.hide && ux.props.hide.includes('sort');
	}
	render() {
		return this.isHidden()?this.html`<div></div>`:this.html`
		<div class="m-dropdownborderless" data-wzb-component="FilterCheckboxes" data-wzb-filter-category="Cat3">
			<div class="m-dropdownborderless__wrapper">
				<div class="m-dropdownborderless__head" data-wzb-filter-head="">
					<span class="m-dropdownborderless__title">Sortieren nach</span>
					<span class="m-dropdownborderless__headarrow"></span>
				</div>
				<ul class="m-dropdownborderless__items">
					${this.sortOptionsMap.map(
					  sortOption => SortOption.for(sortOption)
					)}
				</ul>
			</div>
		</div>
		`;
	}

}

export default Sort;
