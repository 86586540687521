import {Component} from 'hyperhtml';
import config from '../config';
import TagFilterGroup from './tagFilterGroup';
import {ux} from '../store/UxState';

class ResearchGroups extends Component {
	constructor(props) {
		super();
		this.props = props;
		this.researchGroupMap = config.researchGroupMap;
	}
	isHidden() {
		return ux.props.hide && ux.props.hide.includes('tags');
	}
	render() {
		return this.isHidden()?this.html`<div></div>`:this.html`
				<div class="m-dropdown" data-wzb-component="FilterCheckboxes" data-wzb-filter-category="Cat2">
		<div class="m-dropdown__head" data-wzb-filter-head="">
			<span class="m-dropdown__headtitle">Forschungsgruppen</span>
			<span class="m-dropdown__headarrow"></span>
		</div>
		<ul class="m-dropdown__items m-dropdown__items--publication">
			${this.researchGroupMap.map(group => TagFilterGroup.for(group))}
		</ul>
	</div>
		`;
	}

}

export default ResearchGroups;
