import FilteredArticles from './filtered-articles';
import FilterCheckboxes from './filter-checkboxes';
import FilterAlphabet from './filter-alphabet';
import AlphabetIndex from './alphabet-index';

export default (di) => {
	di
		.setType({type: FilteredArticles, name: 'FilteredArticles', parent: 'PageComponent'})
		.setType({type: FilterCheckboxes, name: 'FilterCheckboxes', parent: 'PageComponent'})
		.setType({type: FilterAlphabet, name: 'FilterAlphabet', parent: 'PageComponent'})
		.setType({type: AlphabetIndex, name: 'AlphabetIndex', parent: 'PageComponent'})
	;
};
