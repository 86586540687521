import PageComponent from '../../common/component/page-component';

class AlphabetIndex extends PageComponent {
	constructor({
					root,
					element,
					showLetterClass = 'showLetter',
					letterData = 'filterLetter',
					articleAttribute = 'article',
					activeClass = 'active' //meets the filtering criteria
				}) {
		super({root: root, element: element});
		this.showLetterClass = showLetterClass;
		this.letterData = letterData;
		this.articleAttribute = articleAttribute;
		this.activeClass = activeClass;
	}

	prepare() {
		this.listeners.filterchange = this.events.on(document, 'filteredarticles:change', this.onFilteredArticlesChange.bind(this));
		this.onFilteredArticlesChange();
	}

	onFilteredArticlesChange(e) {
		this.currentArticles = this.element.querySelectorAll(this.dataSelector(this.articleAttribute) + this.classSelector(this.activeClass));
		this.currentArticlesNo = this.currentArticles.length;

		let currentLetter = null;
		for (let i = 0; i < this.currentArticlesNo; i++) {
			const article = this.currentArticles[i];
			const letter = this.dataAttr(article).get(this.letterData);
			if (letter !== currentLetter) {
				this.classList(article).add(this.showLetterClass);
			} else {
				this.classList(article).remove(this.showLetterClass);
			}
			currentLetter = letter;
		}
	}
}

export default AlphabetIndex;
