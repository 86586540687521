import {compose, withState, map} from 'proppy';
import config from '../config';

const QueryState = compose(
	withState('query', 'setQuery', Object.assign({}, config.initialQuery)),
	withState('fix', 'setFix', {}),
	map(props => ({
			fix: props.fix,
			query: Object.assign(props.query, props.fix),
			setFix: props.setFix,
			setQuery: props.setQuery
		}))
);

const qr = QueryState();

export {
	qr
};
