import {Component} from 'hyperhtml';
import {zt} from '../store/ZoteroState';
import {qr} from '../store/QueryState';
import PaginationButton from './paginationButton';

class Pagination extends Component {
	constructor() {
		super();

		this.paginationButton = new PaginationButton();

		zt.subscribe((pr) => {
			this.render();
		});
		qr.subscribe((pr) => {
			this.render();
		});

	}
	render() {
		const pageSize = 20;
		const pageCount = Math.ceil(zt.props.total / pageSize);
		const currentPage = parseInt(qr.props.query.page, 10);
		const paginationButtons = [];
		if (currentPage > 1) {
			paginationButtons.push({type: 'prev', page: Math.max(currentPage - 1, 1)});
		}
		if (currentPage > 3) {
			paginationButtons.push({type: 'ellipsis', page: Math.max(currentPage - 3, 1)});
		}
		for (let i = 1; i <= pageCount; i++) {
			if (Math.abs(currentPage - i) < 3) {
				paginationButtons.push({type: 'page', page: i, active: (i === currentPage)});
			}
		}
		if (currentPage < pageCount - 3) {
			paginationButtons.push({type: 'ellipsis', page: Math.min(currentPage + 3, pageCount)});
		}
		if (currentPage < pageCount) {
			paginationButtons.push({type: 'next', page: Math.min(currentPage + 1, pageCount)});
		}

		return this.html`
			<div class="m-zoteropagination">
				${paginationButtons.map(button => PaginationButton.for(button))}
			</div>
		`;
	}

}

export default Pagination;
