import Masonry from 'masonry-layout';
import PageComponent from '../../common/component/page-component';

class Gallery extends PageComponent {

	constructor({root, element, gridContainerClass = '.wzb-js-gridContainer', gridElementClass = '.wzb-js-gridElement'}) {
		super({root: root, element: element});
		this.gridContainerClass = gridContainerClass;
		this.gridElementClass = gridElementClass;
	}

	prepare() {
		this.masonry = new Masonry(this.element.querySelector(this.gridContainerClass), {
			itemSelector: this.gridElementClass,
			originTop: false,
			transitionDuration: 0});

		this.listeners.context = this.events.on(this.root, 'context:switchcomplete', this.onContextSwitch.bind(this));
		this.listeners.focus = this.events.on(window, 'focus', this.onFocus.bind(this));
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));

		this.listeners.pictureload = this.events.on(this.element, 'picture:load', this.onPictureLoad.bind(this));
	}

	layout() {
		if(this.masonry) this.masonry.layout();
	}

	onPictureLoad() {
		this.layout();
	}

	onContextSwitch(event) {
		this.layout();
	}

	onResize(e) {
		this.layout();
	}

	onFocus(event) {
		this.layout();
	}
}

export default Gallery;
