import onTransitionEnd from './transition-end';
import onAnimationEnd from './animation-end';

const domMixin = (Base = Object) => class extends Base {
	injectEvents(events) {
		this.events = events;
	}


	injectDataAttrParser(dataAttrParser) {
		this.dataAttrParser = dataAttrParser;
	}


	injectDataAttrFactory(dataAttrFactory) {
		this.dataAttrFactory = dataAttrFactory;
	}


	injectCssDataFactory(cssDataFactory) {
		this.cssDataFactory = cssDataFactory;
	}


	injectClassListParser(classListParser) {
		this.classListParser = classListParser;
	}


	injectClassListFactory(classListFactory) {
		this.classListFactory = classListFactory;
	}


	injectThreeStateTransitionFactory(threeStateTransition) {
		this.threeStateTransitionFactory = threeStateTransition;
	}


	classList(element = null, prefix = undefined) {
		if (element === null) {
			return this.selfClassList;
		}
		return this.classListFactory({element: element, prefix: prefix});
	}


	dataAttr(element = null, defaults = {}, prefix = undefined) {
		if (element === null) {
			return this.selfDataAttr;
		}
		return this.dataAttrFactory({element: element, defaults: defaults, prefix: prefix});
	}


	cssData(element = null, defaults = {}, pseudoElement = 'before', property = 'content', varName = 'jsValues') {
		if (element === null) {
			return this.selfCssData;
		}
		return this.cssDataFactory({element: element, defaults: defaults, pseudoElement: pseudoElement, property: property, varName: varName});
	}


	dataSelector(name, value = undefined, prefix = undefined) {
		return this.dataAttrParser.getSelector(name, value, prefix);
	}


	classSelector(name, prefix = undefined) {
		return this.classListParser.getSelector(name, prefix);
	}


	threeStateTransition(element, animatedElement = null) {
		return this.threeStateTransitionFactory(element, animatedElement);
	}


	onTransitionEnd(element, ...params) {
		return onTransitionEnd(element, ...params);
	}


	onAnimationEnd(element) {
		return onAnimationEnd(element);
	}

};


export default domMixin;
