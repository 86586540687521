import {Component} from 'hyperhtml';
import {qr} from '../store/QueryState';
import {ux} from '../store/UxState';

class ReviewedFilterItem extends Component {
	constructor(props) {
		super();
		this.props = props;
		qr.subscribe((pr) => {
			this.render();
		});
		ux.subscribe((pr) => {
			this.render();
		});
	}
	handleEvent(e) {
		const query = qr.props.query;
		query.reviewed = !qr.props.query.reviewed;
		query.page = 1;
		qr.props.setQuery(query);
	}
	isActive() {
		return qr.props.query.reviewed;
	}
	isHidden() {
		return ux.props.hide && ux.props.hide.includes('reviewed');
	}
	render() {
		return this.isHidden()?this.html`<div></div>`:this.html`
		<div class="wzb-filters__filter wzb-filter">
			<div class="wzb-filter__item">
				<label class="wzb-checkbox">
					<input class="wzb-checkbox__checkbox" onChange="${this}" ?checked=${qr.props.query.reviewed} type="checkbox" name="checkbox" value="${qr.props.query.reviewed}">
					<span class="wzb-checkbox__label">${this.props.label}</span>
					<span class="wzb-checkbox__on"><span data-type="svg"><canvas width="15" height="15"></canvas><svg viewBox="0 0 15 15"  role="img"><use xlink:href="#circle-checked"></use></svg></span></span>
					<span class="wzb-checkbox__off"><span data-type="svg"><canvas width="15" height="15"></canvas><svg viewBox="0 0 15 15" role="img"><use xlink:href="#circle-unchecked"></use></svg></span></span>
				</label>
			</li>
	  </div>
		`;
	}

}

export default ReviewedFilterItem;
