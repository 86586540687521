import Lazy from './lazy';


class Container {

	constructor() {
		this.services = {};
		this.values = {};
		this.typeLocked = new Map();

		this.types = {};
		this.mixins = {};
		this.parents = {};
		this.params = {};
		this.setters = {};
		this.initCalls = {};
		this.postCreateCalls = {};

		this.paramsCache = {};
		this.postCreationCache = {};
		this.mixinsCache = {};
	}


	setType({type, name, parent = null, params = null, mixins = [], setters = {}, initCall = [], postCreateCalls = []}) {
		this.types[name] = type;
		this.parents[name] = parent;
		this.params[name] = params;
		this.setters[name] = setters;
		this.mixins[name] = mixins;
		if (!Array.isArray(initCall)) {
			initCall = [initCall];
		}
		this.initCalls[name] = initCall;
		this.postCreateCalls[name] = postCreateCalls;
		return this;
	}


	setMixin({mixin, name, params = null, setters = {}, initCall = [], postCreateCalls = []}) {
		return this.setType({
			type: mixin,
			name: name,
			parent: null,
			params: params,
			setters: setters,
			initCall: initCall,
			postCreateCalls: postCreateCalls
		});
	}


	setParam(name, paramName, value) {
		const params = {};
		params[paramName] = value;
		return this.setParams(name, params);
	}


	setParams(name, params) {
		if (!(name in this.params) || this.params[name] === null) {
			this.params[name] = params;
		} else {
			this.params[name] = Array.isArray(params) ?
				this.params[name].concat(params) :
				Object.assign(this.params[name], params)
				;
		}
		return this;
	}


	setSetter(name, setterName, value) {
		const setters = {};
		setters[setterName] = value;
		return this.setSetters(name, setters);
	}


	setSetters(name, setters) {
		this.setters[name] = Object.assign(name in this.setters ? this.setters[name] : {}, setters);
		return this;
	}


	setInitCall(name, ...methods) {
		this.initCalls[name] = (name in this.initCalls ? this.initCalls[name].concat(methods) : methods);
		return this;
	}


	addPostCreateCall(name, ...callbacks) {
		this.postCreateCalls[name] = (name in this.postCreateCalls ? this.postCreateCalls[name].concat(callbacks) : callbacks);
		return this;
	}


	set(name, service) {
		this.services[name] = service;
		return this;
	}


	setValue(name, value) {
		this.values[name] = value;
		return this;
	}


	has(name) {
		return (name in this.services);
	}


	hasValue(name) {
		return (name in this.values);
	}


	hasType(name) {
		return (name in this.types);
	}


	newFactory(type) {
		return (params = null, setters = {}) => this.newInstance(type, params, setters);
	}


	get(name) {
		if (!(name in this.services)) {
			throw new Error('Service ' + name + ' not defined');
		}
		if (this.services[name] instanceof Lazy) {
			const lazy = this.services[name];
			this.services[name] = lazy.resolveCreation();
			lazy.resolvePostCreation(this.services[name]);
		}
		return this.services[name];
	}


	getValue(name, defaultValue = undefined) {
		if (!(name in this.values)) {
			if (defaultValue !== undefined) {
				return defaultValue;
			}
			throw new Error('Value ' + name + ' not defined');
		}
		if (this.values[name] instanceof Lazy) {
			this.values[name] = this.values[name].resolve();
		}
		return this.values[name];
	}


	lazyNew(name, params = null, setters = {}) {
		return new Lazy(
			() => (this.newInstance(name, params)),
			() => (this.instanceCreation(name, params)),
			(instance) => (this.instancePostCreation(instance, name, setters))
		);
	}


	lazyGet(name) {
		return new Lazy(() => (this.get(name)));
	}


	lazyValue(name, defaultValue = undefined) {
		return new Lazy(() => ((defaultValue !== undefined && !this.hasValue(name)) ? defaultValue : this.getValue(name)));
	}


	lazyCall(callback) {
		return new Lazy(callback);
	}


	lazyGetCall(serviceName, methodName, ...params) {
		return new Lazy(() => this.get(serviceName)[methodName](...params));
	}


	newInstance(name, params = null, setters = {}) {
		const instance = this.instanceCreation(name, params);
		this.instancePostCreation(instance, name, setters);
		return instance;
	}


	instanceCreation(name, params = null) {
		const type = this.getType(name);
		if (this.typeLocked.has(type)) {
			throw new Error('Loop detected in creating an object ' + name);
		}
		this.typeLocked.set(type, true);
		params = this.resolveParams(name, params);
		const instance = this.create(type, params);
		// this.applyMixinConstructors(name, instance);
		this.typeLocked.delete(type);
		return instance;
	}


	instancePostCreation(instance, name, setters = {}) {
		const postCreation = this.resolvePostCreation(name, setters);

		for (const method in postCreation.setters) {
			if (postCreation.setters.hasOwnProperty(method)) {
				if (method in instance) {
					instance[method](postCreation.setters[method]);
				} else {
					throw new Error('Method ' + method + ' not found in instance of ' + name);
				}
			}
		}

		const alreadyCalled = {};
		for (const method of postCreation.initCalls) {
			if (!(method in alreadyCalled)) {
				alreadyCalled[method] = true;
				if (method in instance) {
					instance[method]();
				} else {
					throw new Error('Method ' + method + ' not found in instance of ' + name);
				}
			}
		}

		for (const callback of postCreation.postCreateCalls) {
			callback(instance);
		}
	}


	resolveParams(name, params = null) {
		if (params === null) {
			params = (name in this.params && Array.isArray(this.params[name]) ? [] : {});
		}
		params = Array.isArray(params) ?
			this.fetchPositionalParams(name).concat(params) :
			Object.assign({}, this.fetchNamedParams(name), params)
			;

		if (Array.isArray(params)) {
			for (let i = 0, end = params.length; i < end; i++) {
				if (params[i] instanceof Lazy) {
					params[i] = params[i].resolve();
				}
			}
		} else {
			for (const param in params) {
				if (params.hasOwnProperty(param) && params[param] instanceof Lazy) {
					params[param] = params[param].resolve();
				}
			}
		}
		return params;
	}


	resolvePostCreation(name, setters = {}) {
		const postCreation = Object.assign({}, this.fetchPostCreation(name));
		postCreation.setters = Object.assign({}, postCreation.setters, setters);
		for (const key in postCreation.setters) {
			if (postCreation.setters.hasOwnProperty(key)) {
				if (postCreation.setters[key] instanceof Lazy) {
					postCreation.setters[key] = postCreation.setters[key].resolve();
				}
			}
		}
		return postCreation;
	}


	fetchPositionalParams(name) {
		if (!(name in this.paramsCache)) {
			const parentParams = (name in this.parents ? this.fetchPositionalParams(this.parents[name]) : []);
			const thisParams = (name in this.params ? this.params[name] : []);
			this.paramsCache[name] = parentParams.concat(thisParams);
		}
		return this.paramsCache[name].slice(0);
	}


	fetchNamedParams(name) {
		if (!(name in this.paramsCache)) {
			const parentParams = (name in this.parents ? this.fetchNamedParams(this.parents[name]) : {});
			const thisParams = (name in this.params ? this.params[name] : {});
			this.paramsCache[name] = Object.assign(parentParams, thisParams);
		}
		return Object.assign({}, this.paramsCache[name]);
	}


	fetchPostCreation(name) {
		if (!(name in this.postCreationCache)) {
			const setters = [];
			const initCalls = [];
			const postCreateCalls = [];
			if (name in this.parents && this.parents[name] !== null) {
				const parentPostCreation = this.fetchPostCreation(this.parents[name]);
				setters.push(parentPostCreation.setters);
				initCalls.push(parentPostCreation.initCalls);
				postCreateCalls.push(parentPostCreation.postCreateCalls);
			}

			if (name in this.mixins && this.mixins[name].length) {
				for (const mixin of this.mixins[name]) {
					const mixinPostCreation = this.fetchPostCreation(mixin);
					setters.push(mixinPostCreation.setters);
					initCalls.push(mixinPostCreation.initCalls);
					postCreateCalls.push(mixinPostCreation.postCreateCalls);
				}
			}

			if (name in this.setters) {
				setters.push(this.setters[name]);
			}
			if (name in this.initCalls) {
				initCalls.push(this.initCalls[name]);
			}
			if (name in this.postCreateCalls) {
				postCreateCalls.push(this.postCreateCalls[name]);
			}
			this.postCreationCache[name] = {
				setters: Object.assign({}, ...setters),
				initCalls: [].concat(...initCalls),
				postCreateCalls: [].concat(...postCreateCalls)
			};
		}
		return this.postCreationCache[name];
	}


	// applyMixinConstructors(name, instance) {
	// 	if (!(name in this.mixinsCache)) {
	// 		let mixins = [];
	// 		let item = name;
	//         do {
	// 			if (item in this.mixins && this.mixins[item].length) {
	// 				mixins = this.mixins[item].concat(mixins);
	// 			}
	// 			item = (item in this.parents) ? this.parents[item] : null;
	// 		} while (item);
	// 		this.mixinsCache[name] = mixins;
	// 	}
	// 	const mixins = this.mixinsCache[name]

	//         if (name in this.mixins && this.mixins[name].length) {
	//             for (const mixin of this.mixins[name]) {
	// 				if (mixin in this.mixinsConstructors) {
	// 					const constructorName = this.mixinsConstructors[mixin];
	// 					const params = this.resolveParams(mixin);
	// 					mixinsConstructors.push({name: constructorName, params: params});
	// 				}
	//             }
	//         }

	// 		this.mixinsConstructorsCache[name] = mixinsConstructors;
	// 	}
	// 	return this.mixinsConstructorsCache[name];
	// }


	create(constructor, args) {
		if (args === null) {
			return new constructor();
		}
		if (Array.isArray(args)) {
			return new constructor(...args);
		}
		return new constructor(args);
	}


	getType(name) {
		if (name in this.types) {
			return this.types[name];
		}
		throw new Error('Undefined ' + name);
	}

}


export default Container;
