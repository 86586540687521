class Lazy {

    constructor(callback, creationCallback = null, postCreationCallback = null) {
        this.callback = callback;
        this.creationCallback = creationCallback;
        this.postCreationCallback = postCreationCallback;
    }


    resolve() {
        return this.callback();
    }


    resolveCreation() {
        if (this.creationCallback) {
            return this.creationCallback();
        } else {
            throw new Error('No creation callback defined');
        }
    }


    resolvePostCreation(instance) {
        if (this.postCreationCallback) {
            return this.postCreationCallback(instance);
        } else {
            throw new Error('No post creation callback defined');
        }
    }

}

export default Lazy;
