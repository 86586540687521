import trim from 'locutus/php/strings/trim';
import leftTrim from 'locutus/php/strings/ltrim';
import rightTrim from 'locutus/php/strings/rtrim';
import ucFirst from 'locutus/php/strings/ucfirst';
import lcFirst from 'locutus/php/strings/lcfirst';
import numberFormat from 'locutus/php/strings/number_format';

export {trim, leftTrim, rightTrim, ucFirst, lcFirst, numberFormat};


export function decamelize(value, separator = '-') {
    return value.replace(/([a-z0-9]+)([A-Z]+)/g, '$1' + separator + '$2').toLowerCase();
}


export function camelize(value, setUcFirst = false) {
    value = value.replace(/[-_]+([a-z0-9])/gi, (all, letter) => (letter.toUpperCase()));
    return (setUcFirst? ucFirst(value) : value);
}


export function trimUrl(url) {
	return rightTrim(url, '/');
}


export function nl2br(value) {
	return value.replace(/(\r\n)|(\n\r)|(\n)|(\r)/g, '<br />');
}
