import {Component} from 'hyperhtml';
import {qr} from '../store/QueryState';
import config from '../config';

class Reset extends Component {
	constructor(props) {
		super();
		this.props = props;
		this.timer = null;
		qr.subscribe((pr) => {
			this.render();
		});
	}
	handleEvent(e) {
		qr.props.setQuery(Object.assign({}, config.initialQuery));
	}
	placeholder(){
		var language = window.location.href;
		var languageEnglish = language.includes('/en/')?true:false;
		var placeholder = languageEnglish?"Reset search":"Suche zurücksetzen";
		return placeholder;
	}

	render() {
		return this.html`
			<div class="m-filteredarticles__reset">
				<div class="m-filteredarticles__resetwrapper">
					<button onclick="${this}" class="m-filteredarticles__resetbutton">${this.placeholder}</button>
				</div>
			</div>
		`;
	}

}

export default Reset;
