import { Component } from 'hyperhtml';
import { qr } from '../store/QueryState';
import ItemTypes from './itemTypes';
import config from '../config';
import {ux} from '../store/UxState';
import {zt} from '../store/ZoteroState';




class listTypeFilter extends Component {
  constructor(props) {
    super();
		this.props = props;
        ux.subscribe((pr) => {
			this.render();
		});
		zt.subscribe((pr) => {
			this.render();
		});
		qr.subscribe((pr) => {
			this.render();
		});
  }

  handleEvent(e) {
    const query = qr.props.query;
    const transfer = document.querySelector('#filter-checkbox-transfer')
    const forschung = document.querySelector('#filter-checkbox-forschung')
    const lectures = document.querySelector('#filter-checkbox-lectures')

    const checkbox = e.target;
    query.tags =  query.tags.filter(tag => tag != checkbox.value);

    
    switch (checkbox.value) {
      case 'forschung':
        query.tags =  query.tags.filter(tag => tag !== 'forschung');
        if (checkbox.checked) {
            if(!lectures.checked && !transfer.checked){
                query.tags.push('forschung');
            }else {
                if(transfer.checked){
                    query.tags =  query.tags.filter(tag => tag !== 'transfer');
                    transfer.checked = false 
                }else{
                    query.itemTypes =  query.itemTypes.filter(itemType => itemType !== 'presentation');
                    lectures.checked = false 
                }
                query.tags.push('forschung');
            }
        }  
        query.page = 1;
        qr.props.setQuery(query);

        break;
      case 'lectures':
        query.itemTypes =  query.itemTypes.filter(itemType => itemType !== 'presentation');
        if (checkbox.checked) {
            if(!lectures.checked && !forschung.checked){
                query.itemTypes.push('presentation');
            }else{
                if(forschung.checked){
                    query.tags =  query.tags.filter(tag => tag !== 'forschung');
                    forschung.checked = false 
                }else{
                    query.tags =  query.tags.filter(tag => tag !== 'transfer');
                    transfer.checked = false 
                }
                query.itemTypes.push('presentation');
            }
        }
        query.page = 1; 
        qr.props.setQuery(query);
        break;
      case 'transfer':
        query.tags =  query.tags.filter(tag => tag !== 'TRANSFER');
        if (checkbox.checked) {
            if(!lectures.checked && !forschung.checked){
                query.tags.push('transfer');
                
            }else {
                if(forschung.checked){
                    query.tags =  query.tags.filter(tag => tag !== 'forschung');
                    forschung.checked = false 
                }else{
                    query.itemTypes =  query.itemTypes.filter(itemType => itemType !== 'presentation');
                    lectures.checked = false 
                }
                query.tags.push('transfer');
            }
        }  
        query.page = 1;
        qr.props.setQuery(query);
        break;

      default:
        break;
    }
  }

  render() {
    var language = window.location.href;
	var languageEnglish = language.includes('/en/')?true:false;
    return this.html`
            <div class="m-filter__checkboxcontainer1">
                <label class="m-filter__checkbox">
					<input class="m-filter__checkboxinput" onChange="${this}" ?checked=${false} type="checkbox" name="checkbox" value="${'forschung'}" id="filter-checkbox-forschung">
					<span class="m-filter__checkboxlabel">${!languageEnglish?"Publikationen":"Publications"}</span>
				</label>
            </div>
            <div  class="m-filter__checkboxcontainer3">
                <label class="m-filter__checkbox">
					<input class="m-filter__checkboxinput" onChange="${this}" ?checked=${false} type="checkbox" name="checkbox" value="${'lectures'}" id="filter-checkbox-lectures">
					<span class="m-filter__checkboxlabel">${!languageEnglish?"Vorträge und Präsentationen":"Lectures and Presentations"}</span>
				</label>
            </div>
            <div  class="m-filter__checkboxcontainer3">
                <label class="m-filter__checkbox">
					<input class="m-filter__checkboxinput" onChange="${this}" ?checked=${false} type="checkbox" name="checkbox" value="${'transfer'}" id="filter-checkbox-transfer">
					<span class="m-filter__checkboxlabel">${!languageEnglish?"Transferorientierte Veröffentlichungen":"Transfer-oriented Publications"}</span>
				</label>
            </div>
           
		`;
  }
}

export default listTypeFilter;

{
  /* <li class="m-dropdown__item">
<label class="m-dropdown__checkbox">
    <input class="m-dropdown__checkboxinput" onChange="${this}" ?checked=${this.props.active} type="checkbox" name="checkbox" value="${this.props.id}">
    <span class="m-dropdown__checkboxlabel">${this.tagLabel(this.props.tag)}</span>
</label>
</li> */
}
