import {getScrollTop} from '../../common/utils/get-scroll';
import getOffsetTop from '../../common/utils/offset-top';
import Context from '../../common/context/context';

class DefaultContext extends Context {

	constructor({name, root, popOnKey = false, outClass = 'covered'}) {
		super({name: name, root: root, popOnKey: popOnKey});
		this.prevScrollPos = 0;
		this.outClass = outClass;
	}

	resetPosition() {
		this.prevScrollPos = null;
		this.getElement().style.removeProperty('top');
	}

	switchIn(otherContext) {
		const element = this.getElement();
		this.classList(element).remove(this.outClass);
		element.style.removeProperty('top');
		if (this.prevScrollPos !== null) {
			window.scrollTo(0, this.prevScrollPos);
		}
		this.prevScrollPos = 0;
		return otherContext;
	}


	switchOut(otherContext) {
		const element = this.getElement();
		const currentOffset = getOffsetTop(element);
		const scrollPos = getScrollTop();
		this.prevScrollPos = scrollPos;
		this.classList(element).add(this.outClass);
		element.style.top = (currentOffset - scrollPos) + 'px';
		window.scrollTo(0, 0);
		return otherContext;
	}
}

export default DefaultContext;
