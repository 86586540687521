import {Component} from 'hyperhtml';
import {qr} from '../store/QueryState';

class ItemTypeFilterItem extends Component {
	constructor(props) {
		super();
		this.props = props;
	}
	handleEvent(e) {
	
		const query = qr.props.query;
		query.itemTypes = query.itemTypes.filter(itemType => itemType !== this.props.id);
		if (!this.props.active) {
			query.itemTypes.push(this.props.id);
		}
		query.page = 1;
		qr.props.setQuery(query);
	}
	label(){ 
		var language = window.location.href;
		var languageEnglish = language.includes('/en/')?true:false;
		var placeholder = languageEnglish?this.props.en:this.props.de;
		return placeholder;
	}
	render() {
		if (parseInt(this.props.count, 10) > 0) {
			return this.html`
			<li class="m-dropdown__item">
				<label class="m-dropdown__checkbox">
					<input class="m-dropdown__checkboxinput" onChange="${this}" ?checked=${this.props.active} type="checkbox" name="checkbox" value="${this.props.id}">
					<span class="m-dropdown__checkboxlabel">${this.label()}</span>
				</label>
			</li>
		`;
		} else {
			return this.html``;
		}
	}

}

export default ItemTypeFilterItem;


// button.addevent clieck = padre.classList.toggle("hidden")