import commonConfig from './common';

import animationConfig from '../animation/_config';
import appConfig from '../app/_config';
import contextConfig from '../context/_config';
import contentConfig from '../content/_config';
import galleryConfig from '../gallery/_config';
import menuConfig from '../menu/_config';
import sliderConfig from '../slider/_config';
import filterConfig from '../filters/_config';
import galleryModalConfig from '../gallery-modal/_config';
import zoteroConfig from '../zotero/_config';

const config = (di) => {
	commonConfig(di);

	animationConfig(di);
	appConfig(di);
	contextConfig(di);
	contentConfig(di);
	galleryConfig(di);
	menuConfig(di);
	sliderConfig(di);
	filterConfig(di);
	galleryModalConfig(di);
	zoteroConfig(di);

	const prefix = 'wzb-';
	const classPrefix = 'wzb-js-';

	di
		.setValue('dom/dataAttrPrefix', prefix)
		.setValue('dom/classPrefix', classPrefix)
		.setValue('dom/baseClassPrefix', prefix)
	;

	const bodyData = di.get('dom/dataAttrFactory')({element: document.body});
	const baseUrl = bodyData.get('baseUrl');

	di
		.setValue('baseUrl', baseUrl)
		.setParam('HashScroller', 'duration', 0.5)
	;
};


export default config;
