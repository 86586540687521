import PageComponent from '../../common/component/page-component';

class ZoteroQuery extends PageComponent {

	constructor({
								element,
								root,
								idAttribute = 'checkboxId',
								baseUrlAttribute = 'baseUrl',

								// attributes to set base values for query
								baseQueryAttribute = 'baseQuery',
								baseTagsAttribute = 'baseTags',
								baseItemTypesAttribute = 'baseItemTypes',
								baseSortAttribute = 'baseSort',
								baseDirAttribute = 'baseDir',

								// attributes to hide/show filter options
								showQueryAttribute = 'showQuery',
								showTagsAttribute = 'showTags',
								showItemTypesAttribute = 'showItemTypes',
								showSortAttribute = 'showSort',

								tagAttribute = 'checkboxTag',
								checkboxAttribute = 'checkbox',
								currentPageModifier = 'active',
								sortButtonsAttribute = 'sortButtons',
								searchInputAttribute = 'searchInput',
								sortButtonNameAttribute = 'sortName',
								contentWrapperModifier = 'contentWrapper',
								paginationPagesAttribute = 'paginationPages',
								paginationPageNrAttribute = 'paginationPageNr',
								paginationButtonAttribute = 'paginationButton',
								paginationButtonsAttribute = 'paginationButtons',
								paginationCurrentPageModifier = 'paginationCurrentPage',

							}) {
		super({root: root, element: element});
		this.idAttribute = idAttribute;
		this.tagAttribute = tagAttribute;
		this.baseUrlAttribute = baseUrlAttribute;

		this.baseQueryAttribute = baseQueryAttribute;
		this.baseTagsAttribute = baseTagsAttribute;
		this.baseItemTypesAttribute = baseItemTypesAttribute;

		this.baseSortAttribute = baseSortAttribute;
		this.baseDirAttribute = baseDirAttribute;

		this.showQueryAttribute = showQueryAttribute;
		this.showTagsAttribute = showTagsAttribute;
		this.showItemTypesAttribute = showItemTypesAttribute;
		this.showSortAttribute = showSortAttribute;

		this.checkboxAttribute = checkboxAttribute;
		this.currentPageModifier = currentPageModifier;
		this.sortButtonsAttribute = sortButtonsAttribute;
		this.searchInputAttribute = searchInputAttribute;
		this.contentWrapperModifier = contentWrapperModifier;
		this.sortButtonNameAttribute = sortButtonNameAttribute;
		this.paginationPagesAttribute = paginationPagesAttribute;
		this.paginationPageNrAttribute = paginationPageNrAttribute;
		this.paginationButtonAttribute = paginationButtonAttribute;
		this.paginationButtonsAttribute = paginationButtonsAttribute;
		this.paginationCurrentPageModifier = paginationCurrentPageModifier;

		this.opt = {
			page: 1,
			sort: 'title',
			direction: 1,
			search: '',
			tags: []
		};
		this.ascending = true;
		this.direction = '';
		this.pageNr = 1;
		this.pages = 0;
		this.itemTypes = [];
		this.stopType = false;
		this.hasBaseQuery = false;
		this.hasBaseTags = false;
		this.hasBaseItemTypes = false;
		this.hasPresetFilters = false;

		this.showQuery = true;
		this.showTags = true;
		this.showItemTypes = true;
		this.showSort = true;
	}

	prepare() {
		this.baseUrl = this.dataAttr(this.element).get(this.baseUrlAttribute);

		this.baseQuery = this.dataAttr(this.element).get(this.baseQueryAttribute);
		this.hasBaseQuery = this.baseQuery && this.baseQuery !== '';

		const baseTagsStr = this.dataAttr(this.element).get(this.baseTagsAttribute);
		this.hasBaseTags = baseTagsStr && baseTagsStr !== '';
		if (this.hasBaseTags) this.baseTags = baseTagsStr.split(',');

		const baseItemTypesStr = this.dataAttr(this.element).get(this.baseItemTypesAttribute);
		this.hasBaseItemTypes = baseItemTypesStr && baseItemTypesStr !== '';
		if (this.hasBaseItemTypes) this.baseItemTypes = baseItemTypesStr.split(',');

		this.baseSort = this.dataAttr(this.element).get(this.baseSortAttribute);
		this.hasBaseSort = this.baseSort && this.baseSort !== '';
		if (this.hasBaseSort) this.opt.sort = this.baseSort;

		this.baseDir = this.dataAttr(this.element).get(this.baseDirAttribute);
		this.hasBaseDir = this.baseDir && this.baseDir !== '';
		if (this.hasBaseDir) this.opt.direction = this.baseDir;

		this.hasPresetFilters = this.hasBaseQuery || this.hasBaseTags || this.hasBaseItemTypes;

		this.showItemTypes = this.dataAttr(this.element).get(this.showItemTypesAttribute)+'' !== 'false';
		this.showQuery = this.dataAttr(this.element).get(this.showQueryAttribute)+'' !== 'false';
		this.showSort = this.dataAttr(this.element).get(this.showSortAttribute)+'' !== 'false';
		this.showTags = this.dataAttr(this.element).get(this.showTagsAttribute)+'' !== 'false';
		console.log(this.showTagsAttribute);
		console.log('-' + this.dataAttr(this.element).get(this.showTagsAttribute)+'-');
		console.log(this.showTags);
		this.initContent();
	}

	initContent() {
		const XHR = new XMLHttpRequest();
		const query = this.baseUrl + '?q' +
			(this.hasPresetFilters ? '&hasPresetFilters=true' : '') +
			(this.hasBaseQuery ? '&hasBaseQuery=true' : '') +
			(this.hasBaseTags ? '&hasBaseTags=true' : '') +
			(this.hasBaseItemTypes ? '&hasBaseItemTypes=true' : '') +
			(this.showSort ? '&showSort=true' : '&showSort=false') +
			(this.showTags ? '&showTags=true' : '&showTags=false') +
			(this.showItemTypes ? '&showItemTypes=true' : '&showItemTypes=false') +
			(this.showQuery ? '&showQuery=true' : '&showQuery=false');

		XHR.open('GET', query, true);
		XHR.send();

		XHR.onreadystatechange = () => {
			if (XHR.readyState === 4 && XHR.status === 200) {
				this.element.innerHTML = XHR.responseText;
				this.setup();
			}
		};
	}

	setup() {
		this.checkboxNodes = this.element.querySelectorAll(this.dataSelector(this.checkboxAttribute));
		this.sortButtonsNode = this.element.querySelector(this.dataSelector(this.sortButtonsAttribute));

		this.searchInputNode = this.element.querySelector(this.dataSelector(this.searchInputAttribute));
		this.contentWrapperNode = this.element.querySelector(this.dataSelector(this.contentWrapperModifier));

		this.paginationButtonsNode = this.element.querySelector(this.dataSelector(this.paginationButtonsAttribute));

		this.sortButtons = this.element.querySelectorAll(this.dataSelector('sortName'));
		console.log('this.sortButtons', this.sortButtons);
		this.setListerners();
		this.setPaginationClass();
		this.clearCheckboxes();

		//send query when base is set
		if (this.hasBaseQuery || this.hasBaseTags || this.hasBaseItemTypes) {
			this.sendQuery(this.itemTypes);
		}
	}


	setListerners() {
		this.listeners.sortButtons = this.events.on(this.sortButtonsNode, 'click', this.onClickSort.bind(this));
		this.listeners.paginationButton = this.events.on(this.element, this.dataSelector(this.paginationButtonAttribute), 'click', this.onClickPagination.bind(this));
		this.listeners.searchInputNode = this.events.on(this.searchInputNode, 'input', this.onInput.bind(this));
		this.listeners.searchInputNodeKey = this.events.on(this.searchInputNode, 'keydown', this.onKeyDown.bind(this));

		for (const checkboxNode of this.checkboxNodes) {
			this.listeners.checkbox = this.events.on(checkboxNode, 'change', this.onChange.bind(this));
		}
	}


	onChange() {
		this.itemTypes = this.getCheckedBoxes();
		this.opt.page = 1;
		this.sendQuery(this.itemTypes);
	}


	onClickSort(event, target) {
		const nodeName = target.tagName;

		if (nodeName === 'BUTTON') {
			this.itemTypes = this.getCheckedBoxes();
			const sortType = this.dataAttr(target).get(this.sortButtonNameAttribute);

			this.sortButtons.forEach((button) => {button.classList.remove('js-active');});
			target.classList.add('js-active');

			this.opt.sort = sortType;
			this.opt.direction = sortType==='date' ? -1 : 1; //this.ascending ? 1 : -1;

			this.sendQuery(this.itemTypes);
			this.ascending = !this.ascending;
		}
	}


	onInput(event, target) {
		const searchInput = target.value;
		this.opt.search = searchInput;
		this.opt.page = 1;
		if (!this.stopType) {
			this.stopType = true;
			setTimeout(() => {
				this.sendQuery(this.itemTypes);
				this.stopType = false;
			}, 500);
		}
	}


	onKeyDown(event) {
		// NOTE: Prevent submiting on Enter key
		const key = event.charCode || event.keyCode || 0;
		if (key === 13) {
			event.preventDefault();
		}
	}


	getCheckedBoxes() {
		const items = [];
		this.opt.tags = [];
		this.checkboxNodes = this.element.querySelectorAll(this.dataSelector(this.checkboxAttribute));
		for (const checkboxNode of this.checkboxNodes) {
			if (checkboxNode.checked) {
				const hasTag = this.dataAttr(checkboxNode).has(this.tagAttribute);

				if (hasTag) {
					const tag = this.dataAttr(checkboxNode).get(this.tagAttribute);
					this.opt.tags.push(tag);
				} else {
					const id = this.dataAttr(checkboxNode).get(this.idAttribute);
					items.push(id);
				}
			}
		}
		return items;
	}


	clearCheckboxes() {
		for (const checkboxNode of this.checkboxNodes) {
			if (checkboxNode.checked) {
				checkboxNode.checked = false;
			}
		}
	}


	onClickPagination(event, target) {
		const nodeName = target.tagName;
		const isPageNext = this.dataAttr(target).has('paginationNext');
		const isPagePrev = this.dataAttr(target).has('paginationPrev');
		const isPageNextOrPrev = isPageNext || isPagePrev;

		this.currentPage = this.dataAttr(this.paginationButtonsNode).get(this.paginationCurrentPageModifier);
		this.pages = this.dataAttr(this.paginationButtonsNode).get(this.paginationPagesAttribute);


		if (nodeName === 'BUTTON') {
			if (isPageNextOrPrev) {
				if (isPageNext) {
					if (this.currentPage < this.pages) {
						this.currentPage++;
						this.opt.page = this.currentPage;
						this.sendQuery(this.itemTypes);
					}
				} else {
					if (this.currentPage > 1) {
						this.currentPage--;
						this.opt.page = this.currentPage;
						this.sendQuery(this.itemTypes);
					}
				}
			} else {
				const pageNr = this.dataAttr(target).get(this.paginationPageNrAttribute);
				if (this.currentPage !== pageNr) {
					this.currentPage = pageNr;
					this.opt.page = this.currentPage;
					this.sendQuery(this.itemTypes);
				}
			}
		}
	}


	setPaginationClass() {
		this.paginationButtonsNode = this.element.querySelector(this.dataSelector(this.paginationButtonsAttribute));

		if (this.paginationButtonsNode) {
			this.currentPage = this.dataAttr(this.paginationButtonsNode).get(this.paginationCurrentPageModifier);
			const buttons = this.paginationButtonsNode.querySelectorAll(this.dataSelector(this.paginationButtonAttribute));

			for (const button of buttons) {
				const hasPage = this.dataAttr(button).has(this.paginationPageNrAttribute);
				if (hasPage) {
					const pageNr = this.dataAttr(button).get(this.paginationPageNrAttribute);
					if (this.currentPage === pageNr) {
						this.classList(button).add(this.currentPageModifier);
					}
				}
			}
		}
	}


	sendQuery(itemTypes = []) {

		if (this.hasBaseQuery) this.opt.search = this.baseQuery;
		if (this.hasBaseTags) this.opt.tags = this.baseTags;
		if (this.hasBaseItemTypes) this.itemTypes = this.baseItemTypes;

		const XHR = new XMLHttpRequest();
		const data = {itemTypes: itemTypes, opt: this.opt};
		const json = JSON.stringify(data);
		const query = this.baseUrl + '?methode=methode';
		console.log('sendQuery data', data);

		XHR.open('POST', query, true);
		XHR.setRequestHeader('Content-Type', 'application:json');
		XHR.send(json);

		XHR.onreadystatechange = () => {
			if (XHR.readyState === 4 && XHR.status === 200) {
				const snippet = XHR.responseText;
				this.contentWrapperNode.innerHTML = snippet;
				this.setPaginationClass();
			}
		};
	}
}

export default ZoteroQuery;
