import { Component, wire } from 'hyperhtml';
import config from '../config';

class Item extends Component {
	constructor(props) {
		super();
		this.props = props;
		this.creatorTypes = config.creatorTypes;
		this.optionals1 = config.optionals1;
		this.optionals2 = config.optionals2;
		console.log(props);
	}
	dateStr(dateStr) {
		let date = null;
		if (dateStr) {
			date = new Date(dateStr.replace(/-/g, '/'));
		}
		if (date) {
			return date.toLocaleDateString('de-DE', { dateStyle: 'medium' });
		} else {
			return '';
		}

	}
	creatorString(creator, prevType) {
		const typeStr = this.creatorTypes[creator.type];
		return ((prevType !== creator.type) ? typeStr : '') + creator.firstName + ' ' + creator.lastName;
	}
	optionals(optionalMap) {
		const zoteroItem = JSON.parse(this.props.zoteroItem);
		const infos = optionalMap
			.map(info => ((zoteroItem.hasOwnProperty(info.key) && zoteroItem[info.key] !== '') ? info.label + zoteroItem[info.key] : ''))
			.filter(i => i !== '');
		return wire()`<p class="wzb-filteredArticle__subHead">${infos.join(', ')}</div>`;
	}
	optionalstest(optionalMap) {
		const zoteroItem = JSON.parse(this.props.zoteroItem);
		const infos = optionalMap
			.map(info => ((zoteroItem.hasOwnProperty(info.key) && zoteroItem[info.key] !== '') ? info.label + zoteroItem[info.key] : ''))
			.filter(i => i !== '');
		return wire()`, ${infos.join(', ')}`;
	}
	tagLabel(tag) {
		const tagLabel = config.tagLabels.find(t => t.tag === tag);
		if (tagLabel) {
			return tagLabel.label;
		} else {
			return tag;
		}
	}
	tags() {
		return this.props.tags.filter(tag => tag.tag.substr(0, 1) !== '!').map(tag => '#' + this.tagLabel(tag.tag)).join(' ');
	}
	doi() {
		return this.props.DOI ? wire()`<p class="wzb-filteredArticle__doi">DOI: <a target="_blank" href="https://doi.org/${this.props.DOI}">${this.props.DOI}</a></span>` : null;
	}
	extra() {
		return this.props.extra ? wire()`<p class="wzb-filteredArticle__extra">${this.props.extra}</p>` : null;
	}
	title() {
		const url = this.props.url ? this.props.url : (this.props.DOI ? 'https://doi.org/' + this.props.DOI : null);
		return url ? wire()`<a target="_blank" href="${url}">${this.props.title}</span>` : this.props.title;
	}
	title2() {
		const url = this.props.url ? this.props.url : (this.props.DOI ? 'https://doi.org/' + this.props.DOI : null);
		return url ? wire()`<a target="_blank" href="${url}">${this.props.title}` : this.props.title;
	}
	type() {
		let language = window.location.href
		var languageEnglish = language.includes('/en/') ? true : false;

		return languageEnglish ? this.props.itemType.en : this.props.itemType.de

	}
	stringToHTML(str) {
		var dom = document.createElement('div');
		dom.innerHTML = str;
		return dom;
	};
	// renderOLD() {
	// 	return this.html`
	// 		<div class="m-filteredarticle">
	// 			<div class="m-filteredarticle__separator"></div>
	// 			<div >${this.props.creators
	// 			.map((creator, index, array) => this.creatorString(creator, index > 0 ? array[index - 1].type : ''))
	// 			.join(', ')}
	// 				${this.title2()}					
	// 				${this.optionalstest(this.optionals2)}
	// 				${this.optionalstest(this.optionals1)}
	// 				${this.type()}
	// 			</div>
	// 			<p> </p>
	// 			<div class="m-filteredarticle__endseparator"></div>
	// 		</div>
	// 	`;
	// }

	render() {
		return this.html`
			<div class="m-filteredarticle">
				<div class="m-filteredarticle__separator"></div>
				${this.stringToHTML(this.props.citation)}
				<div class="m-filteredarticle__type">${this.type()}</div>
				<div class="m-filteredarticle__endseparator"></div>
			</div>
		`;
	}
	//<div class="m-filteredarticle__date">${this.dateStr(this.props.date)}</div>
	renderOld() {
		return this.html`
			<li class="wzb-filteredArticle wzb-filteredArticle--publication">
				<div class="wzb-filteredArticle__section">
					<div class="wzb-filteredArticle__sectionColumns">
						<div class="wzb-filteredArticle__sectionColumn">
							<span class="wzb-filteredArticle__subHead">${this.dateStr(this.props.date)}</span>
						</div>
						<div class="wzb-filteredArticle__sectionColumn wzb-filteredArticle__sectionColumn--right">
							<span class="wzb-filteredArticle__subHead">${this.props.itemType ? this.props.itemType.de : ''} ${this.tags()}</span>
						</div>
					</div>
				</div>
				<div class="wzb-filteredArticle__section">
					<p class="wzb-filteredArticle__subHead">
							${this.props.creators
				// .sort((c1, c2) => c1.type > c2.type)
				.map((creator, index, array) => this.creatorString(creator, index > 0 ? array[index - 1].type : ''))
				.join(', ')}
					</p>
				</div>
				<div class="wzb-filteredArticle__section">
					<h2 class="wzb-filteredArticle__head">
						${this.title()}
					</h2>
				</div>
				<div class="wzb-filteredArticle__section">
					<p class="wzb-filteredArticle__subHead">
						${this.optionals(this.optionals1)}
					</p>
					<p class="wzb-filteredArticle__subHead">
						${this.optionals(this.optionals2)}
					</p>
				</div>
				<div class="wzb-filteredArticle__section">
					<div class="wzb-filteredArticle__sectionColumns">
						<div class="wzb-filteredArticle__sectionColumn"></div>
						<div class="wzb-filteredArticle__sectionColumn wzb-filteredArticle__sectionColumn--right">
							${this.doi()}
						</div>
					</div>
				</div>
			</li>
		`;
	}

}

export default Item;
