import {Component} from 'hyperhtml';
import TagFilterItem from './tagFilterItem';
import {qr} from '../store/QueryState';
import {zt} from '../store/ZoteroState';

class TagFilterGroup extends Component {
	constructor(props) {
		super();
		this.props = props;
		zt.subscribe((pr) => {
			this.render();
		});
	}
	getIdOfTag(tagName) {
		const tag = zt.props.tags.find(t => t.tag === tagName);
		if (tag) {
			return tag.id;
		}
		return null;
	}
	render() {
		return this.html`
		<li class="wzb-filter__item wzb-filter__item--subHead">
			<span class="wzb-filter__subhead">${this.props.area}</span>
		</li>
		${this.props.items
			.filter(item => this.getIdOfTag(item) !== null)
			.map(
		  item => {
			  const active = qr.props.query.tags && qr.props.query.tags.includes(this.getIdOfTag(item));
			  return TagFilterItem.for({tag: item, id: this.getIdOfTag(item), active: active});
		  }
		)}
		`;
	}

}

export default TagFilterGroup;
