import Screen from '../utils/screen';

export default function initNav() {
  initMobileToggle();
  initMobileSubmenus();
}

function initMobileToggle() {
  const mobileToggle = document.querySelector('.l-default__menu-toggle');
  const menuWrapper = document.querySelector('.m-mainnavigation__wrapper');
  if (!mobileToggle || !menuWrapper) return;
  mobileToggle.addEventListener('click', () => {
    mobileToggle.classList.toggle('is-active');
    menuWrapper.classList.toggle('is-active');
  });
}

function initMobileSubmenus() {
  const mainNavItemsWithChildren = document.querySelectorAll(
    '.m-mainnavigation__itemwrapper.has-children'
  );
  const accordionToggles = document.querySelectorAll(
    '.m-mainnavigation__subitem__menu-toggle'
  );
  mainNavItemsWithChildren.forEach((item) => {
    const submenuToggle = item.querySelector(
      '.m-mainnavigation__submenu-toggle'
    );
    const submenu = item.querySelector('.m-mainnavigation__submenuwrapper');
    if (!submenuToggle || !submenu) return;
    const backBtn = submenu.querySelector('.m-mainnavigation__submenu-back');

    submenuToggle.addEventListener('click', () => {
      submenuToggle.classList.toggle('is-active');
      submenu.classList.toggle('is-active');
    });
    backBtn.addEventListener('click', () => {
      submenuToggle.classList.toggle('is-active');
      submenu.classList.toggle('is-active');
    });
  });
  accordionToggles.forEach((toggle) => {
    toggle.addEventListener('click', () => {
      const parent = toggle.closest('.m-mainnavigation__subitemwrapper');
      const submenu = parent.querySelector('.m-mainnavigation__submenuwrapper');
      if(!submenu) return;
      toggle.classList.toggle('is-active');
      submenu.classList.toggle('is-active');
    });
  });
}
