import PageComponent from '../component/page-component';


class TogglerController extends PageComponent {

	constructor({
		root,
		element,
		actionValueAttribute = 'togglerControllerActionValue',
		actionOptionsAttribute = 'togglerControllerActionOptions',
		actionToggledOptionsAttribute = 'togglerControllerActionToggledOptions',
		eventType = 'click',
		blurOnToggle = true,
		forAttribute = 'group',
		targetAction = 'toggle',
		beforeToggleEvent = 'togglercontroller:beforetoggle',
		toggleEvent = 'togglercontroller:toggle',
		enabledByCss = false,
		cssEnabledProperty = 'togglerControllerEnabled'
	}) {
		super({root: root, element: element});
		this.defaults.actionValueAttribute = actionValueAttribute;
		this.defaults.actionOptionsAttribute = actionOptionsAttribute;
		this.defaults.actionToggledOptionsAttribute = actionToggledOptionsAttribute;
		this.defaults.eventType = eventType;
		this.defaults.blurOnToggle = blurOnToggle;
		this.defaults.forAttribute = forAttribute;
		this.defaults.targetAction = targetAction;
		this.defaults.beforeToggleEvent = beforeToggleEvent;
		this.defaults.toggleEvent = toggleEvent;
		this.defaults.enabledByCss = enabledByCss;
		this.defaults.cssEnabledProperty = cssEnabledProperty;

		this.busy = false;
		this.updateTimeout = null;
		this.targets = null;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.actionValueAttribute = data.actionValueAttribute;
		this.actionOptionsAttribute = data.actionOptionsAttribute;
		this.actionToggledOptionsAttribute = data.actionToggledOptionsAttribute;
		this.eventType = data.eventType;
		this.blurOnToggle = data.blurOnToggle;
		this.forAttribute = data.forAttribute;
		this.targetAction = data.targetAction;
		this.beforeToggleEvent = data.beforeToggleEvent;
		this.toggleEvent = data.toggleEvent;
		this.enabledByCss = data.enabledByCss;
		this.cssEnabledProperty = data.cssEnabledProperty;

		if ('for' in data) {
			this.targetSelector = this.dataSelector(data.forAttribute, data.for);
		}

		this.targets = this.getTargets();
		this.actions = this.element.querySelectorAll(this.dataSelector(this.actionValueAttribute));
		this.listeners.action = this.events.on(this.element, this.dataSelector(this.actionValueAttribute), this.eventType, this.onAction.bind(this));
		for (let i = 0, end = this.targets.length; i < end; i++) {
			this.listeners['toggle_' + i] = this.events.on(this.targets[i].getElement(), this.targets[i].getToggleEventType(), this.onToggle.bind(this));
		}

		this.updateActionsStatus();
	}


	onAction(event, eventTarget) {
		if (this.blurOnToggle) {
			eventTarget.blur();
		}
		if (!this.enabledByCss || this.cssData().get(this.cssEnabledProperty, true)) {
			const actionData = this.dataAttr(eventTarget);
			const value = !!actionData.get(this.actionValueAttribute);
			const options = actionData.get(this.actionOptionsAttribute, {});
			const toggledOptions = actionData.get(this.actionToggledOptionsAttribute, {});

			this.action(value, options, toggledOptions);
		}
	}


	action(value, options, toggledOptions) {
		return Promise.resolve().then(() => {
			if (this.busy) {
				return false;
			}
			this.busy = true;
			const beforeEvent = this.events.trigger(this.element, this.beforeToggleEvent, {component: this, value: value, options: options, toggledOptions: toggledOptions});
			if (beforeEvent.defaultPrevented) {
				this.busy = false;
				return false;
			}
			const promises = this.getTargets().map((target) => target.toggle(value, options, toggledOptions));
			if (promises.length) {
				return Promise.all(promises).then(() => true);
			}
			return true;
		}).then((complete) => {
			if (complete) {
				this.busy = false;
				this.events.trigger(this.element, this.toggleEvent, {component: this, value: value, options: options, toggledOptions: toggledOptions});
			}
		});
	}


	onToggle(event) {
		if (this.updateTimeout) {
			clearTimeout(this.updateTimeout);
		}

		this.updateTimeout = setTimeout(() => this.updateActionsStatus(), 50);
	}


	updateActionsStatus() {
		const targets = this.getTargets();
		const targetsAmount = targets.length;
		let toggledAmount = 0;
		for (let i = 0; i < targetsAmount; i++) {
			if (targets[i].isToggled()) {
				toggledAmount++;
			}
		}
		for (const action of this.actions) {
			const actionValue = this.dataAttr(action).get(this.actionValueAttribute);
			action.disabled =
				(actionValue === true && toggledAmount === targetsAmount) ||
				(actionValue === false && toggledAmount === 0)
			;
		}
	}


	getTargets() {
		if (this.targets === null) {
			this.targets = this.components.queryComponents(this.root, this.targetSelector);
		}
		return this.targets;
	}

}


export default TogglerController;
