import {Component} from 'hyperhtml';
import {qr} from '../store/QueryState';
import {ux} from '../store/UxState';

class Interval extends Component {
	constructor(props) {
		super();
		this.props = props;
		this.timer = null;
		qr.subscribe((pr) => {
			this.render();
		});
		ux.subscribe((pr) => {
			this.render();
		});
	}
	handleEvent(e) {
		const value = e.target.value;
		clearTimeout(this.timer);
		if (e.target.id === 'start') {
			this.timer = setTimeout(() => this.applyStart(value), 500);
		} else {
			this.timer = setTimeout(() => this.applyEnd(value), 500);
		}
	}
	applyStart(startValue) {
		const query = qr.props.query;
		query.start = startValue;
		if (query.start !== '' && query.start < '2015-01-01') {
			query.start = '2015-01-01';
		}
		if (query.start !== '' && query.end < query.start) {
			query.end = query.start;
		}
		query.page = 1;
		qr.props.setQuery(query);
	}
	applyEnd(endValue) {
		const query = qr.props.query;
		query.end = endValue;
		if (query.end !== '' && query.end < '2015-01-01') {
			query.end = '2015-01-01';
		}
		if (query.start !== '' && query.start > query.end) {
			query.end = query.start;
		}
		query.page = 1;
		qr.props.setQuery(query);
	}
	isHidden() {
		return ux.props.hide && (ux.props.hide.includes('start') || ux.props.hide.includes('end'));
	}
	placeholder(){
		var language = window.location.href;
		var languageEnglish = language.includes('/en/')?true:false;
		var placeholder = languageEnglish?"Time Frame":"Zeitraum";
		return placeholder;
	}

	render() {
		return this.isHidden()?this.html`<div></div>`:this.html`
		<div class="m-dropdown" data-wzb-component="FilterCheckboxes" data-wzb-filter-category="Cat4">
			<div class="m-dropdown__head" data-wzb-filter-head="">
				<span class="m-dropdown__headtitle">${this.placeholder()}</span>
				<span class="m-dropdown__headarrow"></span>
			</div>
			<div class="m-dropdown__items">
				<form class="m-dropdownitem__filterinterval">
					<div class="m-dropdownitem__filterinterval--label">
						<span class="m-dropdownitem__filterinterval--text">von</span>
						<div class="m-dropdownitem__filterinterval--inputwrapper">
							<input class="m-dropdownitem__filterinterval--input" onChange="${this}" id="start" name="start" type="date" value="${qr.props.query.start}">
						</div>
						</div>
					<div class="m-dropdownitem__filterinterval--label">
						<span class="m-dropdownitem__filterinterval--text">bis</span>
						<div class="m-dropdownitem__filterinterval--inputwrapper">
							<input class="m-dropdownitem__filterinterval--input" onChange="${this}" id="end" name="end" type="date" value="${qr.props.query.end}">
						</div>
					</div>
				</form>
	  </div>
	</div>
		`;
	}

}

export default Interval;
