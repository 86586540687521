import {Component} from 'hyperhtml';
import config from '../config';
import {ux} from '../store/UxState';
import {zt} from '../store/ZoteroState';
import {qr} from '../store/QueryState';

import TagFilterItem from './tagFilterItem';
import TagFilterGroup from './tagFilterGroup';
class TagFilter extends Component {
	constructor(props) {
		super();
		this.props = props;
		zt.subscribe((pr) => {
			this.render();
		});
		qr.subscribe((pr) => {
			this.render();
		});
		ux.subscribe((pr) => {
			this.render();
		});
	}
	isHidden() {
		return ux.props.hide && ux.props.hide.includes('itemTypes');
	}

	
	render() {
		
        return this.isHidden() ? this.html`<div></div>`:this.html`
		<div class="m-dropdown" data-wzb-component="FilterCheckboxes" data-wzb-filter-category="Cat3">
				<div class="m-dropdown__head" data-wzb-filter-head="">
					<span class="m-dropdown__headtitle">Tags</span>
					<span class="m-dropdown__headarrow"></span>
				</div>
				<ul class="m-dropdown__items m-dropdown__items--publication">
					${zt.props.tags
						.filter(tag => tag.tag == 'FORSCHUNG' ||tag.tag == 'TRANSFER')
						.sort((a, b) => a.de > b.de && 1 || -1)
						.map(
						tags => {
							const active = qr.props.query.tags && qr.props.query.tags.includes(tags.id);
							return TagFilterItem.for(Object.assign(tags, {active: active}));
						}
						)
					}	
					
				</ul>
			</div>
    `;
           
     
	}

}

export default TagFilter;
