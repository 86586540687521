export default function (element) {
	const eventName = 'animationend';
	return new Promise((resolve) => {
		const callback = () => {
			element.removeEventListener(eventName, callback, false);
			resolve(element);
		};
		element.addEventListener(eventName, callback, false);
	});
}
