import SlideAnimator from './slide-animator';


class SwapClassSlideAnimator extends SlideAnimator {

	constructor({currentClass = 'current', previousClass = 'previous', nextClass = 'next'} = {}) {
		super();
		this.currentClass = currentClass;
		this.previousClass = previousClass;
		this.nextClass = nextClass;
	}


	setup(slideshow) {
		this.slideshow = slideshow;
		this.isCircular = slideshow.isCircular();
		this.slidesCount = slideshow.getSlidesCount();
		const currentSlide = slideshow.getCurrentSlide();
		// this should be already done in the markup, this is just to cover corner cases
		if (currentSlide) {
			this.classList(currentSlide.getElement()).add(this.currentClass);
		}
	}


	switch(currentSlide, newSlide, direction) {
		const newSlideElement = newSlide.getElement();
		if (currentSlide) {
			this.classList(currentSlide.getElement()).remove(this.currentClass);
			// currentSlide.getElement().setAttribute('aria-hidden', 'true');
		}
		if (this.slidesCount > 1) {
			let previousElement = this.slideshow.getElement().querySelector(this.classSelector(this.previousClass) + this.dataSelector(this.slideshow.getSlideAttribute()));
			if (previousElement) {
				this.classList(previousElement).remove(this.previousClass);
			}
			let nextElement = this.slideshow.getElement().querySelector(this.classSelector(this.nextClass) + this.dataSelector(this.slideshow.getSlideAttribute()));
			if (nextElement) {
				this.classList(nextElement).remove(this.nextClass);
			}
			const newIndex = newSlide.getIndex();
			let previousIndex = newIndex - 1;
			if (previousIndex < 0 && this.isCircular) {
				previousIndex = this.slidesCount - 1;
			}
			if (previousIndex >= 0) {
				previousElement = this.slideshow.getSlide(previousIndex).getElement();
				this.classList(previousElement).add(this.previousClass);
			}
			let nextIndex = newIndex + 1;
			if (nextIndex >= this.slidesCount && this.isCircular) {
				nextIndex = 0;
			}
			if (nextIndex < this.slidesCount) {
				nextElement = this.slideshow.getSlide(nextIndex).getElement();
				this.classList(nextElement).add(this.nextClass);
			}
		}

		this.classList(newSlideElement).add(this.currentClass);
		// newSlideElement.setAttribute('aria-hidden', 'false');

		return this.onTransitionEnd(newSlideElement).then(() => {
				newSlideElement.focus({preventScroll: true});
			});
	}
}


export default SwapClassSlideAnimator;
