import PageComponent from '../../common/component/page-component';

class FilteredArticles extends PageComponent {
	constructor({
					root,
					element,
					filterAttribute = 'filter',
					articleAttribute = 'article',
					showMoreAttribute = 'showmore',
					maxInitialArticlesNo = 7,
					showClass = 'show',
					activeClass = 'active', //meets the filtering criteria
					activeNoLetterClass = 'activeNoLetter',
					letterFilterName = 'Letter',
				}) {
		super({root: root, element: element});
		this.filterAttribute = filterAttribute;
		this.articleAttribute = articleAttribute;
		this.maxInitialArticlesNo = maxInitialArticlesNo;
		this.showMoreAttribute = showMoreAttribute;
		this.showClass = showClass;
		this.activeClass = activeClass;
		this.activeNoLetterClass = activeNoLetterClass;
		this.letterFilterName = letterFilterName;
	}

	prepare() {
		this.filters = this.getComponents('FilterCheckboxes', this.element).concat(this.getComponents('FilterAlphabet', this.element));
		this.filtersNo = this.filters.length;

		this.articles = this.element.querySelectorAll(this.dataSelector(this.articleAttribute));
		this.articlesNo = this.articles.length;

		this.showMoreButton = this.element.querySelector(this.dataSelector(this.showMoreAttribute));
		this.listeners.showmore = this.events.on(this.showMoreButton, 'click', this.onShowMore.bind(this));

		this.listeners.filterchange = this.events.on(this.element, 'filter:change', this.onFilterChange.bind(this));

		this.onFilterChange();
	}

	onFilterChange(e) {
		this.getFiltersData();
		this.hideAll();
		this.makeAllActive();
		if (this.filterData.length) {
			//this.makeAllInactive();
			this.filterArticles();
		}

		this.currentArticles = this.element.querySelectorAll(this.dataSelector(this.articleAttribute) + this.classSelector(this.activeClass));
		this.currentArticlesNo = this.currentArticles.length;
		this.showMoreArticles();
		this.checkForShowAll();

		this.events.trigger(this.element, 'filteredarticles:change');
	}

	onShowMore(e) {
		this.showMoreArticles();
	}

	getFiltersData() {
		const filterData = [];
		for (let i = 0; i < this.filtersNo; i++) {
			const filter = this.filters[i];
			if (filter.getHasCheckedElement() === true) {
				filterData.push({name: filter.getName(), data: filter.getData()});
			}
		}
		this.filterData = filterData;
	}


	hideAll() {
		for (let i = 0; i < this.articlesNo; i++) {
			this.classList(this.articles[i]).remove(this.showClass);
			this.currentShowArticleId = 0;
		}
	}

	makeAllActive() {
		for (let i = 0; i < this.articlesNo; i++) {
			this.classList(this.articles[i]).add(this.activeClass);
			this.classList(this.articles[i]).add(this.activeNoLetterClass);
		}
	}

	makeAllInactive() {
		for (let i = 0; i < this.articlesNo; i++) {
			this.classList(this.articles[i]).remove(this.activeClass);
			this.classList(this.articles[i]).remove(this.activeNoLetterClass);
		}
	}

	filterArticles() {
		for (let i = 0; i < this.articlesNo; i++) {
			const article = this.articles[i];
			for (let j = 0; j < this.filterData.length; j++) {
				const filter = this.filterData[j];
				const filterName = filter.name;
				const filterValues = filter.data;
				const articleFilterData = this.dataAttr(article).get(this.filterAttribute + filterName) || '';
				// change of behaviour (18.03.2019)
				// hide element if it doesn't have a particular filter attribute
				//if (articleFilterData) {
					const articleFilterDataArray = articleFilterData.split(' ');
					if (!filterValues.some(r => articleFilterDataArray.indexOf(r) >= 0)) {
						this.classList(article).remove(this.activeClass);
						if (filter.name !== this.letterFilterName) {
							this.classList(article).remove(this.activeNoLetterClass);
						}
					}
				//}
			}
		}
	}

	checkForShowAll() {
		if (this.currentShowArticleId < this.currentArticlesNo) {
			this.showMoreButton.style.display = 'block';
		} else {
			this.showMoreButton.style.display = 'none';
		}
	}

	showMoreArticles() {
		for (let i = this.currentShowArticleId; i < this.currentShowArticleId + this.maxInitialArticlesNo; i++) {
			const el = this.currentArticles[i];
			if (el) {
				this.classList(el).add(this.showClass);
			}
		}
		this.currentShowArticleId += this.maxInitialArticlesNo;

		this.checkForShowAll();
	}
}

export default FilteredArticles;
