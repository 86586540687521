import {compose, withState} from 'proppy';

const UxState = compose(
	withState('noFilter', 'setNoFilter', false),
	withState('hide', 'setHide', []),
 	withState('lang', 'setLang', 'de')
	
);

const ux = UxState();

export {
	ux
};
