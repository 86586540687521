import SlideAnimator from './slide-animator';


class IndexesSlideAnimator extends SlideAnimator {

	constructor({positionAttribute = 'position', balancedPositionAttribute = 'balancedPosition', animateClass = 'animate'} = {}) {
		super();
		this.positionAttribute = positionAttribute;
		this.balancedPositionAttribute = balancedPositionAttribute;
		this.animateClass = animateClass;
	}


	setup(slideshow) {
		this.slideshow = slideshow;
		this.isCircular = slideshow.isCircular();
		this.slidesCount = slideshow.getSlidesCount();
		this.slidesElements = slideshow.getSlides().map((slide) => slide.getElement());
	}


	switch(currentSlide, newSlide, direction) {
		const currentSlideElement = currentSlide.getElement();
		const newSlideElement = newSlide.getElement();
		const currentDataAttr = this.dataAttr(currentSlideElement);
		const newDataAttr = this.dataAttr(newSlideElement);
		const offset = newDataAttr.get(this.positionAttribute) - currentDataAttr.get(this.positionAttribute);
		const balancedOffset = newDataAttr.get(this.balancedPositionAttribute) - currentDataAttr.get(this.balancedPositionAttribute);
		// direction = offset > 0 ? 1 : -1;
		const balancedDirection = balancedOffset > 0 ? 1 : -1;

		const slideshowElement = this.slideshow.getElement();
		this.classList(slideshowElement).add(this.animateClass);

		const balancedUpperLimit = Math.floor(this.slidesCount / 2);
		const balancedLowerLimit = -Math.floor((this.slidesCount - 1) / 2);
		const positionReMap = [];
		const balancedPositionReMap = [];

		for (const slideElement of this.slidesElements) {
			const dataAttr = this.dataAttr(slideElement);
			const currentPosition = dataAttr.get(this.positionAttribute);
			const currentBalancedPosition = dataAttr.get(this.balancedPositionAttribute);
			const newPosition = currentPosition - offset;
			const newBalancedPosition = currentBalancedPosition - balancedOffset;
			dataAttr.set(this.positionAttribute, newPosition);
			dataAttr.set(this.balancedPositionAttribute, newBalancedPosition);
			if (this.isCircular) {
				if (newPosition >= this.slidesCount || newPosition < 0) {
					positionReMap.push({dataAttr: dataAttr, nextValue: (newPosition + this.slidesCount) % this.slidesCount});
				}
				if (newBalancedPosition > balancedUpperLimit || newBalancedPosition < balancedLowerLimit) {
					balancedPositionReMap.push({dataAttr: dataAttr, nextValue: balancedDirection * this.slidesCount + newBalancedPosition});
				}
			}
		}

		return this.onTransitionEnd(newSlideElement).then(() => {
			newSlideElement.focus({preventScroll: true});
			this.classList(slideshowElement).remove(this.animateClass);
			if (this.isCircular) {
				for (const item of positionReMap) {
					item.dataAttr.set(this.positionAttribute, item.nextValue);
				}
				for (const item of balancedPositionReMap) {
					item.dataAttr.set(this.balancedPositionAttribute, item.nextValue);
				}
			}
		});
	}
}


export default IndexesSlideAnimator;
