import {Component} from 'hyperhtml';
import {zt} from '../store/ZoteroState';
import Item from './item';
import Pagination from './pagination';

class Result extends Component {
	constructor() {
		super();

		this.item = new Item();
		this.pagination = new Pagination();
		this.paginationBottom = new Pagination();

		zt.subscribe((pr) => {
			this.render();
		});

	}
	render() {
		return this.html`
		<div class="container">
			<div class="row">
				<div class="m-filteredarticles">
					<div>
						<ul class="m-filteredarticles__list">
							${zt.props.items.filter(item => item.title !== '' ).map(
								item => Item.for(item)
							)}
						</ul>
						<div class="m-filteredarticles__footer">
							${this.paginationBottom}
						</div>
					</div>
				</div>
			</div>
		</div>

		`;
	}

}

export default Result;
