import {Component} from 'hyperhtml';
import config from '../config';
import {ux} from '../store/UxState';
import {zt} from '../store/ZoteroState';
import {qr} from '../store/QueryState';
import ItemTypeFilterItem from './itemTypeFilterItem';
import ItemTypesFilterItem from './itemTypesFilterItem';


class ItemTypes extends Component {
	constructor(props) {
		super();
		this.props = props;
		zt.subscribe((pr) => {
			this.render();
		});
		qr.subscribe((pr) => {
			this.render();
		});
		ux.subscribe((pr) => {
			this.render();
		});
	}
	isHidden() {
		return ux.props.hide && ux.props.hide.includes('itemTypes');
	}
	placeholder(){
		var language = window.location.href;
		var languageEnglish = language.includes('/en/')?true:false;
		var placeholder = languageEnglish?"Publication type":"Publikationstyp";
		return placeholder;
	}
	render() {
		const hideSonstige = true; 
		return this.isHidden() ? this.html`<div></div>`: this.html`
		<div class="m-dropdown" data-wzb-component="FilterCheckboxes" data-wzb-filter-category="Cat1">
				<div class="m-dropdown__head" data-wzb-filter-head="">
					<span class="m-dropdown__headtitle">${this.placeholder()}</span>
					<span class="m-dropdown__headarrow"></span>
				</div>
				<ul class="m-dropdown__items m-dropdown__items--publication">
					${zt.props.itemTypes
						.filter(itemType => itemType.id !== 'note' && itemType.id !== 'attachement')
						.filter(itemType => config.mainItemTypes.includes(itemType.id))
						.sort((a, b) => a.de > b.de && 1 || -1)
						.map(
						itemType => {
							const active = qr.props.query.itemTypes && qr.props.query.itemTypes.includes(itemType.id);
							return ItemTypeFilterItem.for(Object.assign(itemType, {active: active}));
						}
						)}
						${hideSonstige?'':
							ItemTypesFilterItem.for({
							itemTypes: zt.props.itemTypes
							.filter(itemType => itemType.id !== 'note' && itemType.id !== 'attachement')
							.filter(itemType => itemType.count > 0)
							.filter(itemType => !config.mainItemTypes.includes(itemType.id)),
							label: 'Sonstige'
							})
						}
				</ul>
			</div>
    `;
	}

}

export default ItemTypes;
